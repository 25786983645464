import React from 'react'
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import baseMap from 'resources/gis/basemap.json'
import { connect } from 'react-redux'
import { selectRegion } from 'store/regions/actions'
import { fetchData } from 'store/elections/actions'

let mounted = false;

highchartsMap(Highcharts); // Initialize the map module

if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

class MapComponent extends React.Component{

  constructor(props){
    super(props);

    this.state = {
      series : [],
    }
  }
  getPoint(){
    let point = null;
    if(this && this.refs && this.refs.highmap){
      const map            = this.refs.highmap.chart;
      const selectedRegion = this.props.elections.selected;
      const series         = map.series[0];

      if( series && series.points[0] ){
        series.points.forEach( (p)=>{
          if(p.key === selectedRegion){
            point = p;
          }
        });
      }
    }
    return point;
  }

  updateRegion(){
      const selectedRegion = this.props.elections.selected;
      const elements       = document.getElementsByClassName('highlighted');
      const point          = this.getPoint( selectedRegion );
      if(point){
        if(elements && elements[0])
          for(let x = 0; x < elements.length; x++)
            elements[x].classList.remove("highlighted");

        if(point.graphic && point.graphic.element)
          point.graphic.element.classList.add('highlighted');
          
        point.zoomTo();
      }
  }
  componentDidUpdate(){
    mounted = true;
    this.updateRegion();
  }
  componentDidMount(){
    this.updateRegion();
    window.addEventListener("resize", ()=>{
      setTimeout(()=>{
        if(mounted)
          this.updateRegion();
      },100)
    });
  }
  componentWillUnmount() {
    mounted = false;
    window.removeEventListener("resize", this.updateRegion.bind(this));
  }
  render(){
    const { regions, series, elections, fetchData } = this.props;
    const version = elections.update.version;
    return (
      <div id="app-map" className=""  style={ {  } }>
        <HighchartsReact
            constructorType={"mapChart"}
            ref={"highmap"}
            highcharts={Highcharts}
            options={
                {
                  title : false,
                  chart : {
                    height:'100%',
                    animation : false
                  },
                  mapNavigation: {
                      enabled: false,
                      buttons:true
                  },
                  colorAxis: {
                      min: 0
                  },
                  tooltip: false,
                  xAxis: {
                     // minRange: -100 // <- prevent zooming in too much
                  },
                  plotOptions: {
                      series: {
                          animation: false,
                          events: {
                              click: function (e,i) {
                                const region     = regions.data.filter( (r)=>{
                                  return r.code === e.point.key
                                })[0];
                                
                                fetchData(region.code, version);

                              }
                          }
                      }
                  },
                  exporting:false,
                  legend:{
                      enabled: false
                  },
                  dataLabels: {
                      enabled: false,
                      format: '{point.name}'
                  },
                  credits : false,
                  series: [
                      { 
                          data : series ,
                          mapData : baseMap,
                          name    : 'mapdata',
                          joinBy  : ['key','key'],
                      }
                  ]
              }
            }
        />
      </div>
    )
  }
}

const mapState = (s)=>({...s})

const mapDispatchToProps = (dispatch)=> ({
  
  selectRegion : (d)=>{ dispatch( selectRegion(d) ) },

  fetchData : (code, version) => dispatch( fetchData(code, version) )

});

export default connect(mapState, mapDispatchToProps)(MapComponent);
