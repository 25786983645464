import './Map.css';
import { connect } from 'react-redux'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import NacionalCircle from './Nacional';
import MapNavigation from './MapNavigation'
import Regions from './Regions'
import Basemap from './Basemap';
import _ from 'components/Translate'

const MapView = (props)=>{
    
    const basemap = {
        "url" : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        "attribution" : "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
    };

    return (
        <MapContainer 
            center={[16,-24]} 
            zoom={2} 
            style={{height:505}}  
            zoomControl={false}
            scrollWheelZoom={false}
            doubleClickZoom={true}
            dragging={ !L.Browser.mobile }
            touchZoom={ true }
            >
                <ZoomControl position="topleft" />
                <Basemap/>
                <NacionalCircle/>
                <MapNavigation/>
                <Regions/>
        </MapContainer>
    )
}

export default connect( (s)=>({...s}) )(MapView) 