import React, { useState } from 'react'

import { Container, Form, FormControl, Button, Row, Col, Card, Alert } from 'react-bootstrap'

import axios from 'axios'

import { trackPromise } from 'react-promise-tracker'

import MaskInput from 'react-maskinput';
import { useEffect } from 'react';

export default () => {

    const [state, setState] = useState({
        dt_nasc: "",
        nome: ""
    });

    const [rows, setRows] = useState({});

    const [loading, setLoading] = useState(false);

    const [validation, setValidation] = useState({
        valid: false,
        errors: []
    });

    useEffect(()=>{
        document.title = "Consulta Caderno de Recenseamento";
    },[]);

    const handleInput = (e, i) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }   

    const send = () => {
        const options = {            
            params: state
        };

        setValidation({
            valid: true
        })

        if (state.dt_nasc && state.nome) {
            setLoading(true);
            setRows([]);
            trackPromise(
                axios.get('/_getquerypesquisaeleitor', options).then((e) => {
                    //console.log(e)

                    try {
                        const result = e.data.Result;

                        if (!result) {
                            setValidation({
                                valid: false,
                                errors: [
                                    {
                                        type: 'info',
                                        message: "Não encontramos nenhum resultado. Redefina sua consulta"
                                    }
                                ]
                            })
                        } else {
                            setRows(result.Pessoa);
                            setValidation({
                                valid: true
                            })
                        }

                    } catch (err) {
                        console.log(err)
                        setValidation({
                            valid: false,
                            errors: [
                                {
                                    type: 'warning',
                                    message: "Não foi possivel realizar a consulta"
                                }
                            ]
                        })
                    }
                }).then(() => {
                    setLoading(false);
                }).catch((e) => {
                    setLoading(false);
                    setValidation({
                        valid: false,
                        errors: [
                            {
                                type: 'warning',
                                message: "Não foi possivel realizar a consulta"
                            }
                        ]
                    })

                })
            )

        } else {
            setValidation({
                valid: false,
                errors: [
                    {
                        type: 'danger',
                        message: "Preencha os dois campos para realizar a consulta."
                    }
                ]
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        send();
        return false;
    }

    const style = {
        formControl: {
            borderRadius: 8
        }
    };

    const icon = loading ? 'fa-spinner fa-spin' : 'fa-search';

    const maxDate = new Date('10/25/2020');
    maxDate.setDate(maxDate.getDate() - 1);
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    return (
        <>
            <Container fluid id="app-theme" className={`pb-3 px-0 light`} style={{ minHeight: 300, paddingTop: '150px', backgroundPosition: 'fixed' }}>

                <Container className="position-relative app-search-view">
                    <Row className=" w-100 left-0" >
                        <Col className="mb-3">
                            <Card className="shadow border-0" >

                                <Card.Body >

                                    <Row>
                                        <Col lg="6">
                                            <h6 className="text-uppercase" style={{ lineHeight: '25px', color:'#193B73' }}>
                                                <span>Consulte a tua inscrição nos cadernos de recenseamento</span>
                                            </h6>
                                            <p className="mt-3 mb-4 small" style={{color:'#A1A1A1'}}>Para uma pesquisa mais refinada, preencha os dois campos</p>
                                            <Form onSubmit={handleSubmit}>
                                                <Form.Group>
                                                    <Form.Label className="small ml-0"><b>Nome Completo</b></Form.Label>
                                                    <FormControl name="nome" type="text" onChange={handleInput} value={state.nome} style={style.formControl}></FormControl>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label className="small mx-0"><b>Data de Nascimento</b></Form.Label>
                                                    <MaskInput
                                                        placeholder="DD-MM-AAAA"
                                                        className="form-control"
                                                        name="dt_nasc"
                                                        //alwaysShowMask
                                                        //maskChar="_" 
                                                        mask="00-00-0000"
                                                        size={10}
                                                        style={style.formControl}
                                                        onChange={handleInput} value={state.dt_nasc} />
                                                </Form.Group>

                                                <Button type="submit" block onClick={send} className="mt-5 app-local-de-voto-btn mb-4">
                                                    <i className={`fas ${icon}`}></i> Consultar
                                                </Button>

                                            </Form>
                                        </Col>
                                        <Col lg="6">
                                            {
                                                !validation.valid && (
                                                    validation.errors.map((err, i) => (
                                                        <Alert key={i} variant={err.type}>
                                                            {err.message}
                                                        </Alert>
                                                    ))
                                                )
                                            }
                                            <div className="py-3 px-md-3">
                                                {rows.nome_completo && (
                                                    <>
                                                        <div className="app-local-result-item small">
                                                            <b>Nome</b>
                                                            <div>{rows.nome_completo}</div>
                                                        </div>
                                                        <div className="app-local-result-item small">
                                                            <b>Nome da Mãe</b>
                                                            <div>{rows.mae}</div>
                                                        </div>
                                                        <div className="app-local-result-item small">
                                                            <b>Nome do Pai</b>
                                                            <div>{rows.pai}</div>
                                                        </div>

                                                        <div className="app-local-result-item small">
                                                            <b>Data de Nascimento</b>
                                                            <div>{rows.data_nascimento}</div>
                                                        </div>

                                                        {rows.pos_nome && 
                                                            <div className="app-local-result-item small">
                                                                <b>Posto Recenseamento</b>
                                                                <div>{rows.pos_nome}</div>
                                                            </div>
                                                        }

                                                    </>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>

                                    {/*<Row>
                                        <Col>
                                            <div className="">
                                                Linha de apoio grátis <a href="tel:8001014" className="h5 text-blue d-inline-block d-md-block d-lg-inline-block"><b>800 10 14</b></a>
                                            </div>
                                        </Col>
                                    </Row>*/}

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </>
    )
}
