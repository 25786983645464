import React from 'react'

import './Loading.css'

import { usePromiseTracker } from "react-promise-tracker";

export default ()=>{

    const { promiseInProgress } = usePromiseTracker();
    return(
        promiseInProgress && (
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
        )
    )
}