import { FETCH_MESAS_GEO, FETCH_MESAS_REF } from './action-type';
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker';
import { HiddenMesas } from 'components/v2/Map';
import { setAlert, setRegionData } from 'store/elections/actions';
import { Adapter } from 'components/v2/DataAdapter';
import { getRegionName } from 'utils';
import { hideSide } from 'store/theme/actions';

const regionsRequestIds = {};

export const setCreGeo = (data)=>{
    return{
        type : 'SET_CRE_GEO',
        data
    }
}

export const clearSelectedCre = ()=>{
    return {
        type : 'CLEAR',
    }
}

export const selectCre = (code,properties) => async (dispatch,getState) => {
   
    const state      = getState();
    const zones      = state.elections.data.zones;

    if(code /*&& zones && zones[0]*/ ){
        const zoneData = zones.filter( (z)=>z.code?.toLowerCase() === code?.toLowerCase() );
        if(zoneData[0]){
            const Data = Adapter(zoneData[0]);

            dispatch({
                type : 'SELECT_CRE',
                data : code
            } );

            dispatch(setRegionData( Data,{
                ...properties,
                name : getRegionName( properties.code, properties.name )
            }));

            dispatch( hideSide() );

        }else{
           
            dispatch( setAlert([{
                title : getRegionName(properties.code, properties.name?.toUpperCase()),
                message : 'Dados por apurar.'
            }])); 
        }
    }else{
        
        dispatch({
            type : 'SELECT_CRE',
            data : null
        });

    }
}

export const fetchMesas = (props) => async dispatch => {

    try{
        const nocache = new Date().getTime();
        
        const res = await axios.get('/gis/mesas.json?_='+nocache);
        
        dispatch( {
            type: FETCH_MESAS_GEO,
            data: res.data
        })

    }
    catch(e){
        console.log(e)
    }

}


export const fetchMesasRef = (props) => async dispatch =>  {

   const regions = props.regions.data;

   const { version }  = props.elections.update;

   let count = 0;

   const nocache = new Date().getTime();

    if ( regions[0] && version ){

        const  withArr = [];

        regions.forEach( r=> {

            const code = r.code;
           
            if(HiddenMesas[code]){
                count++;
                if(count === regions.length)
                    dispatch( {
                        type: FETCH_MESAS_REF,
                        data: withArr,
                        status: true
                    })
            }
            else if( !regionsRequestIds[code] && !HiddenMesas[code]){
                                   
                regionsRequestIds[code] = true;

                trackPromise(

                    axios.get(`/data/${version}/mesas/${code}.json?=`+nocache).then( (d)=>{

                        count++;
                        
                        try{

                            d.data.forEach((item)=>{
                                withArr.push(item.code)
                            })

                            if(count === regions.length)
                                dispatch( {
                                    type: FETCH_MESAS_REF,
                                    data: withArr,
                                    status: true
                                })
                            
                        }catch(err){
                            console.log(err);
                        }

                    })
                    .catch(function(){
                        count++;
                        if(count === regions.length)
                            dispatch( {
                                type: FETCH_MESAS_REF,
                                data: withArr,
                                status: true
                            })
                    })                 
                )    
            }
        })
        
    }
}


export const fetchMesasData = (props) => async dispatch =>{

    const RequestArray = [];
    const { version }  = props.elections.update;
    const nocache = new Date().getTime();
    const regions = props.regions.data;
    const withArr = [];
    
    regions.forEach( r => {
        const code = r.code;
        if(!HiddenMesas[code])
            RequestArray.push(
                axios.get( `/data/${version}/mesas/${code}.json?=`+nocache).then((d)=>{
                    d.data.forEach((item)=>{
                        withArr.push(item.code)
                    })
                })
            );
    })
    trackPromise(
        axios.all( RequestArray ).finally(()=>{
            dispatch( {
                type: FETCH_MESAS_REF,
                data: withArr
            })
        }).catch(errors => {
            console.log(errors);
        })
    )
}