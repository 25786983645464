import React from 'react'
import { connect } from 'react-redux'
import { Card, Row, Col } from 'react-bootstrap'
import { format, imgPath } from 'utils'
import _ from '../Translate';
import TextTransition from 'react-text-transition'

const NaFrente = (props)=>{
    const Ids = props.ids && props.ids[0] ? props.ids : function(){
        return []
    }();
    const Candidatos = Ids.map( (c,i)=>{
        const candidato = props.candidatos.filter( item=>( item.id === c ) )[0];
        return candidato ? (
            <div key={`candidato-na-frente-${candidato.id}`} className="text-center app-nafrente-candidato p-1" style={ { width:'72px' } }>
                <img alt="candidato" src={ imgPath(candidato.image)} style={ { height:'35px' } } className="shadow"/>
                <div className="small"><b>{candidato.id}</b></div>
            </div>
        
        ) : <div key={i}>{c}</div>
    });
    
    const highlighted = _('Votos');

    return (
       <Card className="card-transparent app-nafrente">
           <Card.Body>
                <h6 className="card-title  text-truncate">
                    <span style={{fontWeight:300}}>Partido com mais</span><TextTransition text={ highlighted  } inline  className="ml-1 destak bolder"/>
                    <div className="position-absolute d-block d-lg-none">
                        <a className="small app-line-h-pct" href="#detalhes">+ detalhes</a>
                    </div>
                </h6>
                <Row>
                    {Ids[0] && (
                        <Col className="d-flex">
                            <div className="d-flex">
                                {Candidatos}
                            </div>
                            <div className="pl-3">
                                <div className=""> 
                                    <b className="app-nafrente-value">{format(props.value)}</b> { _(props.label)  }
                                </div>
                                <div>
                                    {props.pct}%
                                </div>
                            </div>
                        </Col>
                    )}
                    {!Ids[0] && (
                        <Col className="align-items-center">
                            <small>Por apurar...</small>
                        </Col>
                    )}
                    
                </Row>
              
           </Card.Body>
       </Card>
    )
}

export default connect(s=>({...s}))(NaFrente)