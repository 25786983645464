
import { connect } from 'react-redux'
import { toggleCre } from 'store/elections/actions'
import { HiddenMesas } from '.'
import Centers from 'resources/gis/centers.json'
import _ from 'components/Translate'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

const CreController = (props)=>{
    
    const selected        = props.elections.selected;

    const showControl     = !HiddenMesas[selected] && Centers[selected]?.cre === true;

    return (
        <>
        {showControl && (
            <div  className="map-regions-control d-flex align-items-center small bg-white shadow px-3 py-2" style={{borderRadius:10,zIndex:1000}}>
               <input id="cre-toggle" className="mr-2" checked={ props.elections.showCre } onChange={()=>props.dispatch( toggleCre() )} type="checkbox"/>
               <label for="cre-toggle" className="m-0">
                <span>Resultados por </span> 
                <OverlayTrigger
                    placement="bottom"
                    style={{zIndex:150000}}
                    overlay={<Tooltip className="small">Comissões de Recenseamento Eleitoral</Tooltip>}>
                    <b className="d-inline-block" style={{cursor:'help'}}>CRE</b>
                </OverlayTrigger>
               </label>
            </div>
        )}
        </>
    )
}

export default connect( (s)=>({...s}) )(CreController) 