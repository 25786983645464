import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import PieChart from './PieChart';
import TextTransition  from 'react-text-transition'

const ElectedDeputiesStats = ({elections, candidatos})=>{
    
    const { elected } = elections.charts;

    const data = elected && elected.candidates ? elected.candidates.map( item=> {
        const candidato = candidatos.filter( c=>c.id === item.id )[0] || {};
        return {
            name   : item.id,
            y      : item.elected,
            color : candidato.color,
            image : candidato.image
        }
    } ) : [];

    return (
        <div>
            <Card className="card-elected-stats" id="detalhes">
                <Card.Body>
                    
                    <Row>
                        <Col md="8">
                            <div>
                                <b><TextTransition inline text="Deputados Eleitos" /></b>
                            </div>
                            
                            <div className="d-flex mt-4 h4 ">
                                {
                                    elected && (
                                        <>
                                        <div className="text-uppercase font-weight-bold mb-2 mr-5">
                                            <span className="d-block elected-value">{elected.value || 0}</span>
                                            <span className="d-block elected-label">Eleitos</span>
                                        </div>
                                        <div className="text-uppercase font-weight-bold mr-5 elected-item ml-0">
                                            <span className="d-block elected-value">{elected.total}</span>
                                            <span className="d-block elected-label">Total</span>
                                        </div>
                                        <div className="text-uppercase font-weight-bold mb-2 elected-item ml-0">
                                            <span className="d-block elected-value">{elected.toelect}</span>
                                            <span className="d-block elected-label">Por Eleger</span>
                                        </div>
                                        </>
                                    )
                                }
                            </div>
                        </Col>
                        <Col md="4">
                            <PieChart
                                data={data}
                                titleName={ "" } 
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    )
}

export default connect( (s)=>({...s}) )(ElectedDeputiesStats);