
import React, { useEffect } from 'react'
import Data from 'components/Theme/Data'
import Loading from 'components/Loading'
import { Container, Row, Col} from 'react-bootstrap'
import { connect } from 'react-redux'
import { fetchData } from 'store/elections/actions'
import { Link } from 'react-router-dom'
import Footer from 'components/Theme/Footer'
import Moment from 'react-moment'
import { LineHorizontal, SimpleStats } from 'components/Charts'
import _ from 'components/Translate'

const ModTheme = (props) => {
    let _date = '',
        _time = '';

    const {update} = props.elections;
    if(!props.elections.isFetching && props.elections.update){
       // const {update} = props.elections;
        _date = update.date;
        _time = update.time;
    }

    return (    
        <Data notFocus={true}>
            <style>
                {`
                    .stats .card{
                        box-shadow:none!important;
                    }
                    .stats .card-body{
                        padding:0!important
                    }
                `}
            </style>
            <Loading></Loading>
            <Container fluid  className="py-4 theme-mod">
                <main role="main" className="position-relative">
                {_date && 
                    <div style={{fontSize:12}} className="d-block py-2 small text-right">
                         <b >Eleições Presidencias de Cabo Verde</b>
                        <div className="election-titlo text-capitalize" style={{fontSize:10}}>Resultados Provisórios</div>
                        <div className="election-update" style={{fontSize:10}}>Dados atualizados <b><Moment fromNow>{update.timestamp}</Moment></b> </div>
                    </div>
                }
                <div className="stats my-4">
                    <LineHorizontal 
                        title={ _('MESAS') } 
                        totalLabel="Total"
                        {...props.elections.charts?.mesas} 
                        />
                </div>
                    {props.children}

                    <Link to="/" target="_blank" className="btn btn-outline-light text-dark d-block text-right py-2 font-weight-bold position-absolute"  style={{right: '0',bottom : '8px', fontSize: '13px'}}>
                        Mais Detalhes <i className="fas fa-chevron-right small"></i>
                    </Link>
                </main>
                <Row>
                    <Col>
                       <Footer></Footer>
                    </Col>
                </Row>
                
            </Container>
        </Data>
    )
}

export default connect( (state) => ({...state}), dispatch=>({
    setRegion : (code)=>dispatch( fetchData(code) )
}) )(ModTheme) ;