import React, {Component} from 'react'
import { Row, Col, Table, Modal } from 'react-bootstrap'
import {format, ReplaceSpecialChars, uid, imgPath} from 'utils'

class ResultadosZonas extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            show  : this.props.showArea,
            zonas : this.props.zonas,
            isSearching : false
        }
        this.getCandidato = this.getCandidato.bind(this)
    }

    hideModal = () => {
        this.setState({ show: false });
        this.props.resetArea();
    };

    handleonKeyUp = (e) => {
        let value = e.target.value;
        let zonas = value ? this.props.zonas.filter( item=>( ReplaceSpecialChars(item.nome).includes( ReplaceSpecialChars(value) ) ) ) : this.props.zonas;
        this.setState( ()=> ({ 
            zonas : zonas,
            searchText : value,
            isSearching: value?true:false
        }));
    }

    getCandidato(id){
        return this.props.candidatos.filter( item=>( item.id === id ) )[0] || {};
    }

    render() {
        let fristRow = null;
        if(this.state.zonas && this.state.zonas[0] && this.state.zonas[0].votos)
            fristRow = this.state.zonas[0].votos;

        return (
            <>
                
            <Modal
                size="lg"
                show={this.state.show}
                onHide={() => this.hideModal(false)}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="border-0 position-sticky top-0 bg-white pb-0" style={ {zIndex:10} }>
                    <div className="modal-title w-100">
                        <Row>
                            <Col  lg="12" md="12">
                                <div style={{lineHeight:'20px'}} className="mb-0 font-weight-bold h6 modal-title-holder">Resultados por zonas <span className="candidates text-capitalize"></span>
                                    <div className="text-uppercase regions font-weight-bold">{this.props.regions}</div>
                                </div>
                            </Col>
                            <Col  lg="12" md="12" className="mt-3 mt-lg-0">
                                <div className="position-relative my-2">
                                    <input type="text" className="form-control form-control-sm px-4 py-3" onKeyUp={this.handleonKeyUp} placeholder={'Pesquisar Zona...'}/>
                                    <i className="fas fa-search position-absolute text-mute icon-search"></i>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Header>
                <Modal.Body className="pt-0">
                    <Table className="border-0 m-0" hover size="sm">
                        <thead className="pb-3">
                            <tr>
                                <th className="border-0 p-2 pb-4 mxw-50 align-middle" style={{minWidth:190}}>
                                    <div className="icons-legend d-flex">
                                        <div className="mr-3 mt-2">
                                            <span className="d-block small">{" < 50% "}</span>
                                            <div className="zonas-legend status-1"></div>
                                        
                                        </div>
                                        <div className="mx-3 mt-2">
                                            <span className="d-block small">{"> 50%"}</span>
                                            <div className="zonas-legend status-2"></div>
                
                                        </div>
                                        <div className="ml-3 mt-2">
                                            <span className="d-block small"> 100% </span>
                                            <div className="zonas-legend status-0"></div>
                                        </div>
                                    </div>
                                    <span className="small font-weight-bold pt-3 d-block">Mesas Apuradas</span>    
                                </th>
                                {fristRow && fristRow.map(c => {
                                    return (
                                        <th className="border-0 text-center p-2 pb-4 small align-middle" key={uid()}>
                                            <div className="text-center app-voto-candidato d-block mb-2 mx-auto">
                                                <img alt={c.id} src={ `/images/candidatos/${c.id?.toLowerCase()}.jpg` }/>
                                            </div>
                                            <span className="candidates d-block font-weight-bold">{c.id}</span>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.zonas.map(z => {
                                    const clss = z.cre === '' ? 'bg-light ' : '';
                                    const border = z.cre === '' ? 'border-bottom' : '';
                                    const fweight = z.cre === '' ? 'font-weight-bold h6 m-0' : '';
                                    return (
                                        <tr key={uid()} className={`${clss} ${border}`}>
                                            <td className=" mxw-50 border-0 p-2 small align-middle position-relative">
                                                <div className="d-flex w-100 align-items-center">
                                                    <div className={`zonas-legend status-${z.estado}`}></div>
                                                    <div className={`w-100 d-block pl-4 ${fweight}`}>{z.nome}</div>
                                                </div>
                                            </td>
                                            {
                                                fristRow && fristRow.map(zv => {

                                                    const zona = z.votos.filter(zp => zp.id === zv.id)[0];
                                                
                                                    return (
                                                        <td className="border-0 text-center p-2 align-middle holder-highlight" key={uid()}>
                                                            {
                                                                zona && <>
                                                                    {zona.highlight === 1 && <span highlight={zona.id} className="px-2 py-1 area-highlight text-nowrap rounded text-white font-weight-bold d-inline-block" style={{ minWidth:"50px", background:`${this.getCandidato(zv.id).color}`}}>{format(zona.value)}</span>}
                                                                    {zona.highlight === 0 && <span className="px-2 py-1 text-nowrap">{format(zona.value)}</span>}
                                                                </>
                                                            }
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    )
                                }) 
                            }
                            {!this.state.zonas[0] && (
                                <tr><td colSpan="2" className="small">Zona não encontrada...</td></tr>
                            )}
                        </tbody> 
                    </Table>
                </Modal.Body>
            </Modal>
                
            </>              
        )

    }
}

export default ResultadosZonas

