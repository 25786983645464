import './Stats.css'
import React from 'react'
import { connect } from 'react-redux'
import Config from 'config'
import { LineHorizontal, SimpleStats } from 'components/Charts'
import _ from 'components/Translate'
import { Link } from 'react-router-dom'

const  Stats = (props)=>{
    const chartsData = props.elections.charts || {};
    return(
        <div id="stats" className="mt-4 row stats">
            <div className="stats-box mb-4 col-12 ">
                {chartsData.mesas && (
                    <LineHorizontal 
                        title={ _('MESAS') } 
                        totalLabel="Total"
                        titleDetail={ <span className=" ml-2"><Link to={`/${Config.mapURL}`} className="small text-blue-light">+ detalhes</Link></span>}
                        {...chartsData.mesas} 
                        />
                )}
            </div>

            <div className="stats-box mb-4 col-12 ">
                {chartsData.votantes && 
                    <LineHorizontal 
                        totalDescription={ _('INSCRITOS') } 
                        title={ _('VOTANTES') } 
                        {...chartsData.votantes}
                    />
                }
            </div>

            <div className="stats-box mb-4 col-12">
            {chartsData.votantes &&
                <SimpleStats
                    data={[
                        {
                            pct : chartsData.votantes.abstention_pct,
                            value : chartsData.votantes.abstention,
                            title : _('ABSTENCAO'),
                        },
                        {
                            ...chartsData.nulos, 
                            title : _('NULOS'), 
                            color: '#bbb5b7' ,
                            align : 'center'
                        },
                        {
                            ...chartsData.brancos, 
                            title : _('BRANCOS'), 
                            color: '#8b8a90',
                            align : 'right'
                        }
                    ]}
                />
                }
            </div>

        </div>
    );
}

export default connect( (s)=>({...s}) )(Stats) 