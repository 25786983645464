import React from 'react'
import { Card } from 'react-bootstrap'
import { format } from 'utils'

export default (props)=>{
    
    const totalLabel = props.totalLabel || 'Total';
    const titleDetail = props.titleDetail || null;
    const clss  = props.className || '';

    return (
       <Card className={`card-transparent app-line-h ${clss}`}>
          
           <Card.Body>
               {
                   props.title && <h5 className="card-title text-truncate">
                        <b style={{fontWeight:600}}>{props.title}</b>
                        {titleDetail}
                    </h5>
               }
                
                <div className="">
                    <div className="d-flex mb-2 align-items-center">
                        <span className="app-line-h-value">{format(props.value)}</span>
                        <span className="ml-auto app-line-h-pct">{props.pct}%</span>
                    </div>
                    <div className="app-line-h-bar-wrapper mb-1" >
                        <div className="bg-white app-line-h-bar-pct" style={ {width : `${props.pct}%`} }></div>
                    </div>
                    <div className="small pt-1 app-line-h-total ">
                        <span className="">{totalLabel}</span> <span>{format(props.total)}</span>
                        { props.totalDescription && <span> {props.totalDescription}</span> }
                    </div>
                </div>
           </Card.Body>
       </Card>
    )
}