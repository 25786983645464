
import Config from 'config'

const dateUeff = Config.dates.showUrnsEff;
const dateEl  = Config.dates.showElection;

const initialState = {
    isFetching : false,
    fetched  : false,
    currentDate : false,
    isElectionDay : false,
    isAffluenceDay : false,
    electionDateTimestamp : Config.dates.electionDateTimestamp,
    electionDate : new Date(
        dateEl.year,
        dateEl.month, 
        dateEl.day, 
        dateEl.hours, 
        dateEl.minutes, 
        dateEl.seconds,
        dateEl.millis
    ),
    affluenceDate : new Date(
        dateUeff.year,
        dateUeff.month, 
        dateUeff.day, 
        dateUeff.hours, 
        dateUeff.minutes, 
        dateUeff.seconds,
        dateUeff.millis
    ),
    urnas : {
        version : {},
        lastUpdate: {}
    },
    clock : {
        currentTime : null,
        endTime : null,
        timeLeft : {
            dias: null,
            horas: null,
            minutos: null,
            segundos: null
        }
    }
};

export default (state = initialState, action) => {

    switch(action.type){

        case 'FETCHING':
            return{
                ...state,
                isFetching: true
            }
        
        case 'TICK' : 

            const durationMs = performance.now() - action.startTime;
      
            let date = new Date( action.startTime );

            if(action.startTime){

                const seconds = date.getSeconds();

                const durMillis = new Date(durationMs).getMilliseconds();

                date = date.setSeconds( seconds + 1 );

                date = (new Date(date)).setMilliseconds( durMillis );

                return {
                    ...state,
                    currentDate : date
                }

            }
            

        break;

        case 'SET_TIME':

            const isElectionDay = action.data.lastUpdate.getTime() >= state.electionDate.getTime() || action.data.status === true;
            
            return {
                ...state,
                isElectionDay,
                isFetching : false,
                fetched :true,

            }

        case 'SET_CURRENT_TIME':

            const tz = action.data.timezone;

            const difference = 1634500800000  - tz ;

            const isDate     = difference <= 0 || action.data.status == "1";

            let timeLeft = {};

            if (difference > 0) 
                timeLeft = {
                    dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    horas: Math.floor((difference / (1000 * 60 * 60)) % 24),
                    minutos: Math.floor((difference / 1000 / 60) % 60),
                    segundos: Math.floor((difference / 1000) % 60),
                };
            
            return {
                ...state,
                isElectionDay : isDate,
                clock : {
                    ...state.clock,
                    currentTime : tz,
                    timeLeft
                },
                fetched :true,

            }
        
        default:
                return{
                    ...state
                }
    }

  return state;

};

