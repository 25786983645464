import './Ranking.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import { format, imgPath, isTabletOrMobile } from 'utils'
import { Card } from 'react-bootstrap';
import flagCorner from 'images/flag.corner.png'
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react';

const Ranking = (props)=>{
    const { votos } = props.elections.charts;
    const getCandidato = (id) => {
        return props.candidatos.filter( item=>( item.id.toLowerCase() === id.toLowerCase() ) )[0] || {};
    }
   
    useEffect( ()=>{
        const ranking = document.querySelector('#ranking ');
        const rankingScroll = ranking.querySelector('.d-flex.position-relative');
        rankingScroll.scroll({
            left: 0,
            behavior: 'smooth'
        });
        if( isTabletOrMobile() )
            window.scroll({
                top:0,
                behavior: 'smooth'
            })
    },[props.elections.regionName, props.elections.selected] );

    return (
        <div id="ranking" className="mt-4 ">
            <img className="bg-blue d-none d-lg-block" src={flagCorner}/>
            <div className="text-blue title px-4 pt-3">{_('RANKING_TITLE')}</div>
            <div className="d-flex position-relative px-4 py-3 " style={{marginLeft:-10,marginRight:-10, overflow:'auto'}}>
                {
                    !votos[0] && (
                        props.candidatos.map( (c,i)=>(
                            <div key={i+'-'+c.id} className="box w-100 px-2">
                                <Skeleton width={'100%'} height={260}></Skeleton>
                            </div>
                        ))
                    )
                }
                {votos.map( (c,i)=>{
                    const candidato = getCandidato(c.id);
                    return (
                        <div key={i+'-'+c.id} className={`box w-100 px-2 ${ c.highlight == "1" ? 'highlight' : '' }`}>
                            <div 
                                className='box-image'
                                style={{ 
                                    //backgroundImage:`url(${imgPath(candidato.image)})` , 
                                    backgroundImage:`url(/images/candidatos/${candidato.id?.toLowerCase()}.jpg)` , 
                                   
                                }}
                            />
                            <Card className="box-info text-blue">
                                <Card.Body>
                                    <div className=" box-title truncate-2">
                                        <span className="text-uppercase">{candidato.name}</span>
                                    </div>
                                    <div className="pt-3 pb-1">
                                        <b className="box-pct font-weight-bold">{c.pct} %</b>
                                    </div>
                                    <div className="box-bar d-flex">
                                        <div className="box-bar-color shadow" style={{backgroundColor:candidato.color}}></div>
                                        <div className="box-bar-wrapper">
                                            <div className="box-bar-pct" style={{backgroundColor:candidato.color, width:`${c.pct}%`}}></div>
                                        </div>
                                    </div>
                                    <div className="pt-2 d-flex align-items-center w-100 justify-content-center">
                                        <span style={{fontSize:14,fontWeight:600}}>{format(c.votes)}</span>
                                        <small className="ml-1">votos</small>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                })}
               
            </div>
        </div>
    )
}

const mapStateToProps = (s)=>({...s});


export default connect( mapStateToProps )(Ranking) 