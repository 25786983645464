import React from 'react'
import {format, imgPath, uid } from 'utils'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { showElectedModal } from  'store/elections/actions'
import Modal from './ModalElected2'

const Loading = ()=>{

    const arr = function(){
        var r = [];
        for(var i = 0; i< 7;i++){
            r.push(i)
        }
        return r;
    }();

    return arr.map((i)=>(
        <div className="pb-3 position-relative mb-1 col-sm-6 col-md-12 px-lg-0" key={i}>
            <div className="d-flex align-items-center">                   
                <Skeleton width={26} height={26} circle={true}></Skeleton>
                <div className="info-votos pl-3 w-100">
                    <div className="d-flex">
                        <div className="d-flex font-weight-bold position-relative" style={ { top:-2 } }>
                            <Skeleton width={40}></Skeleton>
                        </div>
                    </div>
                    <Skeleton width={'100%'}></Skeleton>
                </div>
            </div>
        </div>
      
    ))
}

const Votos2 = (props)=>{
    const getCandidato = (id) => {
        return props.candidatos.filter( item=>( item.id === id ) )[0] || {};
    }
    const showModal = (id)=>{
        props.dispatch( showElectedModal(id) );
    }

    return (
        <>
         <Modal></Modal>
         <SkeletonTheme color="#e8e8e8" highlightColor="#fff">
             <div className=" app-votos">
                <div className="mb-4"></div>
                 <div className="row m-0 ">
                    
                    {props.fetching && (
                        <Loading></Loading>
                    )}
                    { props.votos && props.votos[0] && (
                        props.votos.map( (item,i) => {
                            return (
                                <div className="pb-4 mb-1 position-relative mb-1 col-md-12 p-0 px-lg-0" key={item.id+'-'+i+uid()}>
                                    <div className="d-flex align-items-center">
                                        <div className="d-flex flex-column align-items-center flex-md-row text-center">
                                            <div className="text-center app-voto-candidato d-flex align-items-center">
                                                <img alt={item.id} style={ {borderRadius:0} } src={ imgPath(getCandidato(item.id).image )}/>
                                            </div>
                                            <div style={ { width:45 } }>
                                                <b className="ml-0 ml-md-1 app-voto-name text-truncate text-uppercase">{item.id}</b>
                                            </div>
                                        </div>
                                        <div className="w-100 d-flex align-items-center ml-2" >
                                            <div className="w-100 d-lg-flex align-items-center app-votos-info" style={{}}>
                                                <div style={ { minWidth:100 } }>
                                                    <b className=" app-voto-value text-right" style={{fontSize:13}}>{format(item.votes)}</b>
                                                    <span className="ml-2 app-voto-pct text-right" style={{fontSize:13}}>{item.pct}%</span>
                                                </div>
                                                <div className="app-line-h-bar-wrapper w-100" >
                                                    <div className="app-line-h-bar-pct" style={ {width : `${item.pct}%`, background:`${getCandidato(item.id).color}`} }></div>
                                                </div>
                                            </div>
                                            
                                            <div className={`d-flex align-items-center ml-2 ${ item.elected && 'cursor-pointer' }`}  style={ !item.elected ? { color:'#a7a7a7' } : {} } onClick={ item.elected ? ()=>{ showModal( item.id )  } : ()=>{return} }>
                                                <b>{item.elected}</b>
                                                <span className="px-1">
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </span>
                                                <span>
                                                    <span className="d-block shadow ml-1" style={ {background:getCandidato(item.id).color, width:10,height:15,borderRadius:5}  }></span>
                                                </span>
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>
                            )
                        }))
                    }
                 </div>
             </div>
         </SkeletonTheme>
         </>
    )
}
export default connect( (s)=>({...s}))(Votos2)