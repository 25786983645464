import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from 'axios';
import { setCurrentTime as setTime } from 'store/countdown/actions'

import 'moment/locale/pt'
import moment from 'moment-timezone'

function App(props) {

    const {isElectionDay, clock} = props.countdown;

    const currentTime = props.countdown.clock.currentTime;

    const timeLeft = props.countdown.clock.timeLeft;


    useEffect(() => {
        let timeout;

        if(currentTime){

            timeout = setInterval(() => {

                props.dispatch( setTime( {
                    timezone: currentTime + 1000
                }) );
                
            }, 1000);
        }
            

        return ()=>clearInterval(timeout);

    },[ currentTime ]);

    useEffect(() => {

        //setTimeLeft(calculateTimeLeft());

    },[currentTime]);

    const timerComponents = [];


    useEffect( ()=>{
        /*axios.get('getTime.json').then( (d)=>{

            props.dispatch(setTime(d.data.timestamp));

        } )*/
    },[null] )

    Object.keys(timeLeft).forEach((interval,i) => {
        /*if (!timeLeft[interval]) {
        return;
        }*/

        timerComponents.push(
        <span key={i} className="d-block text-center px-2 clock-item-holder position-relative" style={{width:70}}>
            <span className="font-weight-500 d-block  bg-white text-blue rounded shadow py-2">  { timeLeft[interval] < 10 ? '0' : '' }{ timeLeft[interval]} </span> 
            <small className="text-light" style={{fontSize:12,fontWeight:300}}>{interval}</small>
        </span>
        );
    });
    return (
        <div className="d-flex small mt-5 mb-5  position-relative clock-holder" style={{fontSize:20}}>
            {timerComponents.length ? timerComponents : <span></span>}
        </div>
    );
}

export default connect( s=>({...s}) )(App);