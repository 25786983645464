import React from "react";
import $ from "jquery";
// Import Highcharts
import Highcharts from "highcharts/highmaps";
import drilldow from "highcharts/modules/drilldown";
import dataModule from "highcharts/modules/data";
//import mapData from "./mapData";
import baseMap from 'resources/gis/basemap.json'
import HighchartsReact from "highcharts-react-official";
import Axios from "axios";

drilldow(Highcharts);
dataModule(Highcharts);

var data       = Highcharts.geojson(baseMap);

// Set drilldown pointers
data.forEach(function(el, i) {
    el.drilldown = el.properties.key
    el.value = i; // Non-random bogus data
});

window.Highcharts = Highcharts;

let regions = [];

class App extends React.Component {

  render() {

    regions = this.props.regions.data;

    console.log(this.props.series);

    console.log(data);

    return (
      <div>
        <HighchartsReact

          highcharts={Highcharts}

          options={{

                title : false,

                chart: {
                    events: {
                        
                        drilldown: function(e) {
                      
                            if (!e.seriesOptions) {

                                var chart   = this,
                                   
                                    mapKey  = e.point.drilldown,

                                    name = e.point.name,
                                    
                                    regionData  = regions.filter( (r)=>{
                                        return r.code === mapKey
                                    } )[0],

                                    /*fail    = setTimeout(function() {
                                        if (!Highcharts.maps[mapKey]) {
                                            chart.showLoading('<i class="icon-frown"></i> Failed loading ' + e.point.name);
                                            fail = setTimeout(function() {
                                                chart.hideLoading();
                                            }, 1000);
                                        }
                                    }, 3000),*/

                                    parent = regionData.parent_code || '',

                                    path   = '/map/geojson/'+parent+'/'+regionData.code+'/json.geojson';


                                // Show the spinner
                                chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner

                                Axios.get(path).then((v)=>{
                                    
                                    var geojson = v.data;

                                    if(geojson){
                                        
                                       var data = Highcharts.geojson(geojson);

                                        $.each(data, function(i) {
                                            this.value = i;
                                        });
                                        
                                        chart.hideLoading();

                                        chart.addSeriesAsDrilldown(e.point, {
                                            name: e.point.name,
                                            data: data,
                                            dataLabels: {
                                                enabled: false,
                                                format: "{point.name}"
                                            }
                                        });

                                        this.setTitle(null, { text: e.point.name });
                                    }

                                }).catch((e)=>{
                                    chart.showLoading('<i class="icon-frown"></i> Failed loading ' + name);
                                    chart.hideLoading();
                                }).finally( (e)=>{
                                    chart.hideLoading();
                                });
                             
                            }
                            
                        },
                        
                        drillup: function() {
                            this.setTitle(null, { text: "" });
                        }
                    }
                },
            
                legend:{
                    enabled: false
                },
            
                colorAxis: false,
            
                mapNavigation: {
                    enabled: false,
                    buttons:true
                },
            
                xAxis: {
                    minRange: -100 // <- prevent zooming in too much
                },
            
                plotOptions: {
                    series: {
                        animation: true,
                        events: {}
                    }
                },
            
                series: [
                    {
                        data:this.props.series,
                        mapData : data,
                        joinBy  : ['key','key'],
                        dataLabels: false
                    }
                ],
            
                drilldown: {
                    activeDataLabelStyle: {
                        color: '#FFFFFF',
                        textDecoration: 'none',
                        textOutline: '1px #000000'
                    },
                    drillUpButton: {
                        relativeTo: 'spacingBox',
                        position: {
                            x: 0,
                            y: 60
                        }
                    }
                }
            }}
            constructorType={"mapChart"}
        />
      </div>
    );
  }
}

export default App;
