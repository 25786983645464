import './Theme.css' 
import React, {useEffect, useState} from 'react'
import Data from '../../Theme/Data'
import Loading from 'components/Loading'
import { Container, Row, Col, ListGroup} from 'react-bootstrap'
import { connect } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { fetchData, cleanAlerts } from 'store/elections/actions'
import Config from 'config'
import Sidebar from '../Sidebar'
import Header from '../Header'
import Ranking from '../Ranking'
import Map from '../Map'
import Stats from '../Stats'
import { LineHorizontal, SimpleStats } from 'components/Charts'
import _ from 'components/Translate'
import { Link } from 'react-router-dom'
import { hideSide } from 'store/theme/actions'
import RegionData from '../RegionData'
 
import { withRouter } from 'react-router-dom';

const Theme = (props)=>{
    
    const chartsData                = props.elections.charts || {};
    const selectedCode              = chartsData?.selected;
    const newUpdates                = props.elections.newUpdates || [];
    const showSidebar               = props.theme.sidebar === false ? false : true;
    const Alerts                    = props.elections.alerts || [];
    const {history}                 = props;
    const [ visible, setVisible ]   = useState(true);
    const [ sideClss, setSideClss ] = useState("");
    const { addToast }              = useToasts();
    const isTabletOrMobile          = ()=> window.innerWidth <= 1200;
    
    const handleCollpase = ()=>{
        if( isTabletOrMobile() ) 
            setSideClss( sideClss === 'tablet-side' ? '' : 'tablet-side' )
        else
            setVisible(!visible);
    }

    const afterRegionSelectOnTablet = ()=>{
        if( isTabletOrMobile() )
            setSideClss( sideClss === 'tablet-side' ? '' : 'tablet-side' )
    }

    const getRegion = (id)=> props.regions.data.filter( region=>region.code?.toLowerCase() === id?.toLowerCase() )[0];

    const DisplayUpdate = (props)=>{
        const items = props.items || [];
        const message = props.message || '';
        return (
            <div className="small">
                <div className="pt-1 px-2">{message}</div>
                <ListGroup variant="flush" style={{maxHeight: 'auto', overflow:'auto'}} >
                    {items.map(mesa=>{
                        const region = getRegion(mesa.key);
                        return region ? (
                            <ListGroup.Item key={mesa.key} style={ {fontSize:10} } action  className="app-updates-item d-flex pl-2 " onClick={()=>{
                                if(props.onClick) 
                                    props.onClick(mesa.key)
                            }}>
                                <b>{region.name}</b>
                                <i className="ml-auto fas fa-chevron-right small"></i>
                            </ListGroup.Item>
                        ) : <></>
                    })}
                </ListGroup>
            </div>
        )
    }

    useEffect(()=>{
        if(newUpdates[0]){
            if(newUpdates.length >= 3)
                addToast(
                    <DisplayUpdate 
                        items={newUpdates} 
                        onClick={props.setRegion}
                        message={ <><b>{newUpdates.length}</b> círculos <b>100% apurados</b></> }/>, {
                        appearance:'info',
                        autoDismiss:true,
                        autoDismissTimeout:10000
                });
            else
                newUpdates.forEach(mesa=>{
                    const region = getRegion(mesa.key);
                    console.log(mesa)
                    if(region)
                        addToast( 
                            <DisplayUpdate items={[mesa]} message={<><b>100%</b><span> de mesas apuradas em</span></>}></DisplayUpdate>,{
                            appearance:'info',
                            autoDismiss:true,
                            onClick:()=>{
                                props.setRegion(mesa.key)
                            }
                        })
                });
        }
    },[ newUpdates ]);

    useEffect(()=>{
  
        if(Alerts[0]){
            Alerts.forEach( a=>{
       
                addToast(
                    <div>
                        <small><b>{ a.title || getRegion(a.region)?.name }</b></small>
                        <div>
                            {a.message}
                        </div>
                    </div>, {
                            appearance: a.type || 'info',
                            autoDismiss:true,
                            autoDismissTimeout:4500
                });
            });
        }
    },[ Alerts ]);

    
    const path = props.location?.pathname?.replace('/','');

    return(
        <Data>
            <Loading></Loading>
            <Container fluid className={ `sidebar-${ showSidebar ? 'on' : 'off' }` } path={ path }>
                <div className="app-elections-v2 row">
                    <Sidebar ></Sidebar>
                    <Col id="main" className="p-4">
                        <div>
                            {props.children}
                        </div>
                    </Col>
                </div>
                <div className="app-overlay" onClick={ ()=>props.dispatch( hideSide() ) }></div>
            </Container>

            <RegionData></RegionData>
            
        </Data>
    );
}

export default connect( (state) => ({...state}), dispatch=>({
    dispatch : dispatch,
    setRegion : (code)=>dispatch( fetchData(code) ),
    cleanAlerts : ()=>dispatch( cleanAlerts() )
}) )( withRouter(Theme)) 

/*
connect( (state) => ({...state}), dispatch=>({
    setRegion : (code)=>dispatch( fetchData(code) ),
    cleanAlerts : ()=>dispatch( cleanAlerts() )
}) )(Theme) ;

*/