
import  { SET_DATA, SET_UPDATE, SHOW_ELECTED_MODAL, HIDE_ELECTED_MODAL, SHOW_CRE, HIDE_CRE, TOGGLE_CRE, SET_REGION_ZONES } from './action-type'

import clean from 'resources/cleandata.json'

const initialState = {
    alerts : [],
    first : true,
    update : {},
    selected : "global",
    clean : true,
    isFetching : false,
    data   : {
        results : clean,
        zones   : null,
        postos : null,
        postosGis : null
    },
    charts : clean.graphics.global,
    series : clean.series,
    closedCircles : null,//[],
    newUpdates : [],
    electedModalData : {
        data : null,
        id : null,
        properties:null
    },
    showCre : true,
    region : {
        data : null,
        properties : null
    },
    regionName : ''
};

export default (state = initialState, action) => {

    if(action.type === "RERESH_DATA"){
        return {
            ...state,
            data : {
                ...state.data
            },
            refreshed : true
        }
    }
       
    if(action.type === "FETCHING")
        return {
            ...state,
            isFetching : true
        }

    if(action.type === "FINICHED_FETCHING")
        return {
            ...state,
            isFetching : false
        }
    
    
    if(action.type === "SET_REGION"){

        return {
            ...state,
            selected : action.data.selected
        }

    }
        
    if(action.type === SET_DATA){

        const data           = action.data;
        const resultsData    = data.results;
        const zonesData      = data.zones?.data;
        const resultsGraphics     = Object.keys(resultsData.graphics).length    > 0 ? resultsData.graphics : { [action.code] : clean.graphics.nacional };
        const series = action.data.results.series ? action.data.results.series : [];
        const closedCircles = series.filter((item)=>{
            return item.pct === 100
        });

        const zonasList = resultsGraphics[action.code]?.zonas;

        const newUpdates = state.closedCircles ? closedCircles.filter(item=>{
            return state.closedCircles?.filter( c=>c.key === item.key ).length == 0
        }) : [];

        return {
            ...state,
            selected : action.code,
            isFetching: false,
            data : {
                ...state.data,
                results : {
                    graphics : {
                        ...state.data.results.graphics,
                        ...resultsGraphics
                    },
                    series : [...action.data.results.series]
                },
                zones : action.data.zones,
                postos : zonasList,
                postosGis : null
            },
            charts: resultsGraphics[action.code] || clean.graphics.global,
            series : [...action.data.results.series],
            clean : false,
            first : false,
            closedCircles : closedCircles[0] ? closedCircles :  null,
            newUpdates,
            region : {
                data : null,
                properties : null
            },
            regionName : '',

        }

    }
    


    if(action.type === 'SET_REGION_DATA'){
        
        const data  = action.data.charts;
        const props = action.data.properties;

        const rtn = {
            ...state,
            region : { 
                ...props
            },
            regionName : props.name, 
        }

        if(action.data.charts){

            const key   = Object.keys(action.data.charts?.graphics)[0];

            rtn.charts = data.graphics[key];

            rtn.data = {
                results : {
                    graphics : data,
                    series : state.data.results.series
                },
                zones : state.data.zones,
                postos : state.data.postos
            }

            return rtn;
        }

    }

    if(action.type === 'SET_POSTOS_DATA'){

        return {
            ...state
        }

    }

    if(action.type === SET_REGION_ZONES){
        return {
            ...state
        }
    }

    if(action.type === SET_UPDATE)
        return {
            ...state,
            //isFetching: true,
            update : action.data
           
        }

    if(action.type === SHOW_ELECTED_MODAL){
        /*
        let electedParty = state.charts.elected && state.charts.elected.candidates[0] ? state.charts.elected.candidates : [];

        if(action.data)
            electedParty = electedParty.filter( i=>i.id === action.data )
        
        let electedIDs = [];

        electedParty.forEach((c,i)=>{
            electedIDs = [...electedIDs, ...c.names];
        });*/
        console.log(action);
        return {
           ...state,
           electedModalData : action.data
        }
    }

    if(action.type === HIDE_ELECTED_MODAL){
      
        return {
           ...state,
           electedModalData : {
               data:null,
               id:null,
               properties:null
           }
        }
    }

    if(action.type === 'SET_ALERT'){
   
        return {
           ...state,
           alerts : action.data
        }
    }

    if(action.type === SHOW_CRE){
   
        return {
           ...state,
           showCre : true
        }
    }

    if(action.type === HIDE_CRE){
   
        return {
           ...state,
           showCre : false
        }
    }

    if(action.type === TOGGLE_CRE){

        return {
           ...state,
           showCre : !state.showCre
        }
    }


  return state;

};

