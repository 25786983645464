import axios from "axios";
import Config from 'config';
import Names from 'resources/gis/names.json'

export const format = (x)=> x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : 0;

export const capitalize = (s) => {
    if (typeof s !== 'string') return ''

    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  export const capitalize2 = (word)=> {
	return word
	  .toLowerCase()
	  .replace(/\w/, firstLetter => firstLetter.toUpperCase());
  }

  export const isTabletOrMobile    = ()=> window.innerWidth <= 768;

  export function sortByKey(array, key) {
		return array.sort(function(a, b) {
			var x = a[key]; var y = b[key];
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});
	}

export const CallService = (p) => {

    let method  = p.hasOwnProperty('method') ? p.method.toLowerCase() : 'get',
        headers = {};

    if (p.hasOwnProperty('headers'))
        headers = p.headers;
      
    if (p.hasOwnProperty('data'))
        return axios[method](`${p.path}`,p.data,headers);
    else
        return axios[method](`${p.path}`,headers);

}

export const UpperToCapitalize = (s) => {
    if (typeof s !== 'string') return '';

    return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase();
}

export const ZeroPad = (x) => {
    if (x < 10) {
        x = '0' + x;
    }
    return x;
}

export const getRegionName = (code, other)=>{
	return Names[code?.toUpperCase()] || other || '';
}

export const ReplaceSpecialChars = (val) => {
	var chars = ['[',']','€','«','»',';','='];
	var accents = [
		{ base:'a', accents:['\u00c4' ,'\u00c3','\u00e1','\u00e0','\u00e2','\u00e3','\u00e4','\u00c1','\u00c0','\u00c2'] },
		{ base:'e', accents:['\u00e9','\u00e8','\u00ea','\u00ea','\u00c9','\u00c8','\u00ca','\u00cb'] },
		{ base:'i', accents:['\u00ed','\u00ec','\u00ee','\u00ef','\u00cd','\u00cc','\u00ce','\u00cf'] },
		{ base:'o', accents:['\u00d6','\u00d5','\u00f3','\u00f2','\u00f4','\u00f5','\u00f6','\u00d3','\u00d2','\u00d4'] },
		{ base:'u', accents:['\u00fa','\u00f9','\u00fb','\u00fc','\u00da','\u00d9','\u00db'] },
		{ base:'c', accents:['\u00e7','\u00c7'] },
		{ base:'n', accents:['\u00f1','\u00d1'] }
	];

	//replace white spaces to _
	var str = val.replaceAll(' ','_');
	//replace - to _
	str = str.replaceAll('-','_');
	//replace special characters
	str = str.replace(/[&\/\\#,+()$~%.'":*?<>!?@´ªº^|£§{}]/g,'');
	//replace symbols
	chars.forEach(function(c){
		str = str.replaceAll(c,'');
	});
	//replace accents
	accents.forEach(function(aObj){
		aObj.accents.forEach(function(a){
			str = str.replaceAll(a,aObj.base);
		});
	});

	return str.toLowerCase();
}

export const uid = ()=>{	
	function s4() {				
		return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	}
	return s4()+'-'+ s4()+'-'+s4();
}

export const electionDay = () => {
	const day = sessionStorage.getItem(Config.sessionVar);
	return day !== null && day === "true";
}

export const removeSession = (key) => {
	sessionStorage.removeItem(key);
}

export const setSession = (key,val) => {
	sessionStorage.setItem(key,val);
}

export const getSession = (key) => {
	sessionStorage.getItem(key);
}

export const imgPath = (path) => {
	return  path ? ( path.indexOf('/') === 0 ? path : '/'+path) : path;
}

export const dateStringToObject = (string) => {
	const arr = string.split('-');
	return {
		day : arr[0]*1,
		month : arr[1]*1 - 1,
		year : arr[2]*1
	}

}

export const timeStringToObject = (string)=>{

	const arr = string.split(':');

	return {
		hours   : arr[0]*1,
		minutes : arr[1]*1,
		seconds : arr[2]*1
	}

}

export default {
    format : format
}