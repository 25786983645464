
import React from 'react';
import '../gis-map.css';
import MapView from './Components/MapView';
import axios from 'axios';
import config from 'config';
import { useMap } from "react-leaflet"
import { Container, Row, Col, Modal } from 'react-bootstrap';

import { connect } from 'react-redux';
import { trackPromise } from 'react-promise-tracker';

import MesaDetails from 'components/Charts/MesaDetails';

import { useToasts } from 'react-toast-notifications'
import { fetchMesas } from 'store/gis/actions';
import { SHOW_MESAS_WITHOUT_RESULTS, SHOW_MESAS_WITH_RESULTS } from 'store/gis/action-type';

const defaultCenter = { lat: 16, lng: -24 };

const defaultZoom   = 3 ;

let L;

const CentersAndZooms = {
  global : {
    center: defaultCenter,
    zoom  : defaultZoom
  },
  nacional : {
    center: defaultCenter,
    zoom  : 8
  },
  sa : {
    center: { lat: 17.071422733700807,lng: -25.140891175933525 },
    zoom  : 11
  },
  sv:{
    center : {lat: 16.84834661720827,lng: -24.958003556310384},
    zoom: 11
  },
  snc:{
    center : {lat: 16.60533370574678,lng: -24.243933621596266},
    zoom: 11
  },
  sal:{
    center : {lat: 16.726895362979697,lng: -22.930334470789127},
    zoom: 11
  },
  bv:{
    center : {lat: 16.095365368232645,lng: -22.80220511189877},
    zoom: 11
  },
  ma:{
    center : {lat: 15.224862488049991, lng: -23.154758245255415},
    zoom: 11
  },
  stn:{
    center : {lat: 15.16814091082254,lng: -23.674053959883423},
    zoom: 11
  },
  sts:{
    center : {lat: 14.990257472856303,lng: -23.527206488595798},
    zoom: 11
  },
  fg:{
    center : {lat: 14.946310736734565,lng: -24.37667536887233},
    zoom: 11
  },
  br:{
    center : {lat: 14.85455748049475,lng: -24.70379181246248},
    zoom: 11
  },
  diaspora : {
    center : defaultCenter,
    zoom : defaultZoom
  },
  af:{
    center : {lat: 6.6471441804261735,lng: 20.703891070412357},
    zoom: 3
  },
  erm:{
    center : {lat: 51.43209462844014,lng: 27.345721876933677},
    zoom: 3
  },
  am:{
    center : {lat: 28.706016841210396, lng: -95.59382346791139},
    zoom: 3
  },
}

const Popup = (props)=>{
  return (
    <div className="app-mesas-popup">
      <div>
        <div className="d-block mb-2">{props.DESCRICAO} <br></br><b>{props.MES_REFERENCIA}</b> </div>
        {
          /*
          <div className="mt-1 small">Clique no ponto para mais detalhes</div>
          */
        }
      </div>
    </div>
  )
}

const withToast = (Component)=>{
  return function WrappedComponent(props) {
    const toastFuncs = useToasts()
    return <Component {...props} {...toastFuncs} />;
  }
}


class App extends React.Component{

  constructor(props){
    
    super(props);

    this.state = {
      data     : null,
      center : defaultCenter,
      zoom : defaultZoom,
      filter : false,
      selected : null,
      selectedData : null,
      regions: this.props.regions.data,
      isLoadedMesa: false
    }

    this.mapClick = this.mapClick.bind(this);
    this.markerClick = this.markerClick.bind(this);
    this.onFilterClick = this.onFilterClick.bind(this);
    this.showMesas = this.showMesas.bind(this);
    this.config = config.gis;

    this.regionsRequestIds = {};

    L = window.L;

  }

  componentDidMount(){
    this.props.dispatch ( fetchMesas(this.props) );   
  }
  
  fetchMapData(code){

    const { version }  = this.props.elections.update;
    
    return trackPromise(
      axios.get(`/data/${version}/mesas/${code}.json`).then( (d) => { 
        return d.data;
    }))
  }

  componentDidUpdate(){
    
  }

  showMesas(type){

    if(type === SHOW_MESAS_WITH_RESULTS)
        this.props.dispatch({
            type: type,
            status: !this.props.gis.showMesasWithResults
        });

    if(type === SHOW_MESAS_WITHOUT_RESULTS)
        this.props.dispatch({
            type: type,
            status:  !this.props.gis.showMesasWithOutResults
        });
  } 

  setExtent(e){

    const coords = e?.geometry?.coordinates;
    if(coords){
      const point = L.latLng( [ coords[1], coords[0]] );    
      const bounds = L.latLngBounds(point, point);

      this.mapReference.fitBounds( L.latLngBounds(bounds) );
    }
    
  }

  markerClick(e){
   
    const feature      = e.layer && e.layer.feature ? e.layer.feature : e;
    const {properties} = feature;
    const code         = properties.SIGLA_CONCELHO
    const { version }  = this.props.elections.update;

    this.setExtent(e);
   
    if(code && version){
      trackPromise(
        axios.get(`/data/${version}/mesas/${code}.json`).then( (d)=>{    
          const mesaData = d.data.filter( m=> m.code === properties.MES_REFERENCIA  )[0];
          if(mesaData){
            this.setState({
              selected : feature,
              selectedData : mesaData
            })
          }else{
            this.props.addToast(<div><b style={{fontSize:13}}>{properties.DESCRICAO}</b><br/>Mesa por apurar</div>,{
              appearance: 'warning',
              autoDismiss: true,
            });
          }
        }).catch( (err)=>{
          console.log(err)
        })
      );
    }
  }

  mapClick(e){
    this.clearSelected();
  }

  getAppGeoJSONLayer(m){
    let map = m || this.mapReference;

    const layer = Object.values(map._layers).filter( l=>l.options.id === "app-markers-cluster" )[0];
    this.geoJSONLayer = layer;
    this.geoJSONFeatures = layer.getLayers();
    return layer
  }

  getFeatureByCode(map,code){
    const layer = this.geoJSONLayer ||  this.getAppGeoJSONLayer(map);
    return this.geoJSONFeatures.filter( item=>item.feature.properties.MES_REFERENCIA === code )[0];

  }

  getMarkerData(){
    
    const map = this.mapReference
    const { version }  = this.props.elections.update;

    if( version && map && map.getZoom() >= 2 && map.getZoom() <= 12){
        
        const regions = {};
        
        map.eachLayer( layer=>{
    
            if( layer instanceof L.Marker && map.getBounds().contains(layer.getLatLng()) ){
                
              const featureLayer = layer.getAllChildMarkers ? ( map.getZoom()  > 2 ? layer.getAllChildMarkers()[0] : {} ) : layer;
                const region = featureLayer.feature && featureLayer.feature.properties ? featureLayer.feature.properties.SIGLA_CONCELHO : null;
              
                console.log(region)
                if(region)
                    regions[region] = true
            }
        });
      
        Object.keys(regions).forEach( r=>{
          if( !this.regionsRequestIds[r]){

            this.fetchMapData(r).then( (d)=>{
              d.forEach((item)=>{
                const feature = this.getFeatureByCode( map, item.code );
                if(feature){
                  const icon = new L.DivIcon({
                    html: '<i class="fas fa-map-marker-alt"></i>',
                      className: 'text-center app-map-icon closed',
                      iconSize: new L.Point(40, 40)
                  });
                  feature.setIcon(icon);
                }
              });
            });

            this.regionsRequestIds[r] = true;
          }

        });

    }
  }

  onMoveEnd(e){
                  
    //this.getMarkerData()

  }

  clearSelected(){
    this.setState({
      selected : null,
      selectedData: null
    })
  }

  onFilterClick(filter){
    this.setState({
      filter : filter
    })
  }

  render(){
    
    const featureSelected = this.state.selected;
    const selectedData = this.state.selectedData;
    const {selected} = this.props.elections;

    const center = (selected in CentersAndZooms) ? CentersAndZooms[selected].center : this.state.center;
    const zoom   = (selected in CentersAndZooms) ? CentersAndZooms[selected].zoom : this.state.zoom;

    return (
      <Container fluid={true} >
        <Row className="app-stats-wrapper">
          <Col sm="12" className="p-0">
            
            <MapView
              {...this.props}
              filter={this.state.filter}
              config={this.config} 
              zoom={zoom} 
              center={center} 
              data={this.props.gis.mesas?.geojson} 
              mapClick={ this.mapClick }
              showMesas={ this.showMesas }
              markerClick={ this.markerClick }
              selected={ this.props.elections.selected}              
              whenReady={(o)=>{
                this.mapReference = o.target;
              }}
            />
            {
              featureSelected && (
                <Modal size="xl" className="app-mesas-modal" show={this.state.selectedData ? true : false} onHide={ this.clearSelected.bind(this) }>
                  <Modal.Header closeButton className="font-weight-bold  text-blue border-0">
                     <div>{featureSelected.properties.DESCRICAO}</div>                    
                  </Modal.Header>
                  <Modal.Body className="p-0 m-0">
                    <div className="pt-0 pl-3 pb-3">
                      <div className="small">
                        <div>
                          <span className="font-weight-500">Mesa: </span> {featureSelected.properties.MES_REFERENCIA}
                        </div>
                        <div>
                          <span className="font-weight-500">Nome de Posto: </span>{featureSelected.properties.POSTO}
                        </div>
                        <div className="pt-3">
                          { selectedData.imagem_edital && <a href={selectedData.imagem_edital} target="_blank">Edital</a> }
                        </div>
                      </div>
                      
                    </div>
                    <MesaDetails 
                      mesa={featureSelected.properties}
                      data={selectedData}
                    />
                    
                  </Modal.Body>
                  <Modal.Footer>
                    
                  </Modal.Footer>
                </Modal>
              )
            }
            

          </Col>
        </Row>
        
        <Container fluid={true} style={{maxWidth:this.config.containerWidth}} className="app-data-wrapper">
          {
            /**
             * 
             * <GlobalStats onFilter={ this.onFilterClick } config={this.config} data={this.state.data} selected={ this.state.selected }></GlobalStats>
             */
          }
          </Container>
      </Container>
    ) 
  }

}


export default connect( (s)=>({...s}))(withToast(App));
