import './RegionData.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import { Modal } from 'react-bootstrap';
import { hideElectedModal } from 'store/elections/actions';
import { LineHorizontal } from 'components/Charts';
import VotesTable from '../VotesTable';

const RegionData = (props)=>{
    const regionData = props.elections.electedModalData;
    const region = props.regions.data.filter( (r)=>r.code === props.elections.selected )[0] || {}
    return (
        <Modal id="region-data"  className="stats region-data-stats" show={regionData.id ? true : false} onHide={()=>{props.dispatch( hideElectedModal() )}}>
            <Modal.Body className="p-4">
                <div className="d-md-flex">
                    <div className="mr-md-4" style={{width:250}}>
                        <div className="modal-title text-blue text-capitalize" style={{fontWeight:500}}>
                            {regionData.properties?.NOME?.toLowerCase()}
                        </div>
                        <div className="modal-subtitle text-capitalize" style={{fontSize:14}}>
                            {region.name?.toLowerCase()}
                        </div>
                    </div>
                    <div className="mt-md-0 mt-3">
                        <LineHorizontal
                            title={true}
                            titleDetail={<span className="small">Mesas apuradas</span>}
                            totalLabel="Total"
                            pct={regionData.data?.mesas_pct}
                            value={regionData.data?.mesas_value}
                            total={regionData.data?.mesas_total}
                        />
                    </div>
                </div>
                <div className="mt-4">
                    <VotesTable/>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default connect( (s)=>({...s}) )(RegionData) 