import React from 'react'

const GoTop = ()=>{
    return(
        <div className="text-center d-flex justify-content-center d-md-none" style={{ color:'rgba(255,255,255,.75)' }}>
            <div className="cursor-pointer h3 mt-3" onClick={()=>{
            window.scrollTo(0, 0);
        }}>
                <i className="fas fa-chevron-circle-up"></i>
            </div>
        </div>
    )
}
export default GoTop;