import { SET_REGIONS, SELECT_REGION } from './action-type';
import axios from 'axios'

export const setRegions = data => ({
    type: SET_REGIONS, 
    data
});

export const selectRegion = data => ({
    type: SELECT_REGION, 
    data
});

export const getRegions = () => async dispatch => {

    try{
        const nocache = new Date().getTime();
        
        const res = await axios.get('/data/regions.json?_='+nocache);
        
        dispatch( {
            type: SET_REGIONS,
            data: res.data
        })
    }
    catch(e){
        console.log(e)
    }

}