
import axios from 'axios'
import { trackPromise } from 'react-promise-tracker';
import { SET_DATA, SET_UPDATE, SHOW_ELECTED_MODAL, HIDE_ELECTED_MODAL, SHOW_CRE,HIDE_CRE, TOGGLE_CRE } from './action-type';
import { hideSide } from '../theme/actions'
import { HiddenMesas } from 'components/v2/Map' 
import { clearSelectedCre } from 'store/gis/actions';

export const requestData = (region)=>({
    type : 'REQUEST_DATA',
    region
});

export const setRegion = data => ({
    type: "SET_REGION", 
    data
});

export const fetching = () => ({
    type: "FETCHING"
});

export const finishFetching = () => ({
    type: "FINICHED_FETCHING"
});

export const setData = (code, data) => ({
    type: SET_DATA, 
    data,
    code
});

export const setRegionData = (data, properties)=>({
    type : 'SET_REGION_DATA',
    data : {
        charts : data,
        properties : properties
    }

})

export const setPostoData = (data)=>({
    type : 'SET_POSTO_DATA',
    data

})

export const setUpdate = data => ({
    type: SET_UPDATE, 
    data
});

export const showElectedModal = data => ({
    type: SHOW_ELECTED_MODAL, 
    data
});

export const hideElectedModal = data => ({
    type: HIDE_ELECTED_MODAL, 
    data
});


export const setContext = data => ({
    type: "SET_CONTEXT", 
    data
});

export const refresh = () => ({
    type: "RERESH_DATA",
});

export const setAlert = (data)=>({
    type : "SET_ALERT",
    data
})

export const cleanAlerts = ()=>({
    type : "SET_ALERT",
    data : []
})

export const showCre = ()=>({
    type : SHOW_CRE
})

export const hideCre = ()=>({
    type : HIDE_CRE
})

export const toggleCre = ()=>({
    type : TOGGLE_CRE
})

export const fetchVersion = (code)=>{

    return (dispatch, getState)=>{

        const nocache = new Date().getTime();

        const {update, selected} = getState().elections;

        return axios.get('/data/version.json?_='+nocache).then( d => {

            let updated = false;
            let dataRequest;

            if(d.data && d.data.version && d.data.version !== update.version){

                dispatch( setUpdate( d.data ) );

                dataRequest = dispatch( fetchData( selected, d.data.version ));
            
                updated = true;

            }

            return {
                updated,
                time : d.data.time,
                dataRequest
            }

        });

    }

}

export const fetchData = (_code, v)=>{

    const code = _code || 'global';

    return (dispatch, getState, p) => {

        const state = getState();
        const { elections } = state;
        const version = v || elections.update.version;

        if(elections.clean)
            dispatch(
                fetching()
            );
        
        const RequestArray = [];
        
        RequestArray.push( axios.get(`/data/${version}/${code}.json`) );

        if(elections.showCre && !HiddenMesas[code])
            RequestArray.push( axios.get(`/data/${version}/cre/${code}.json`) );

        return trackPromise(

            axios.all( RequestArray ).then(axios.spread((...responses) => {
                const data     = responses[0];
                dispatch( finishFetching() );
                if(data.data && data.data.graphics && data.data.graphics[code]){
                    
                    dispatch( setData( code, {
                        results : data.data,
                        zones   : responses[1]?.data
                    }));

                    dispatch( clearSelectedCre(null) ); 

                    dispatch( hideSide() );
                }
                else
                    dispatch(setAlert([{
                        region : code,
                        message : 'Dados por apurar...',
                        type : 'warning'
                    }]))

            })).catch(errors => {
                
                console.log(errors);
    
            })
        )
        
    };
}


