import React, {Component}  from 'react'
import { connect } from 'react-redux'
import {  Row, Col,  Card } from 'react-bootstrap'
import { Candidates, LineHorizontal, SimpleStats, Mapcrumb, ResultadosZonas,Map, Genero, FaixaEtaria } from 'components/Charts'
import Footer from 'components/Theme/Footer'
import _ from 'components/Translate';
import { Link, NavLink } from 'react-router-dom'
import Config from 'config'
import { setContext } from 'store/elections/actions'
import ElectedDeputiesStats from 'components/Charts/ElectedDeputiesStats'
import VotosEleitos from 'components/Charts/VotosEleitos'

import MapLeaflet from 'components/Map/App/App';
import GoTop from 'components/Theme/GoTop'

class Dashboard extends Component {
    
    constructor(props){
        super(props)
        
        this.state = {
            showArea : false,
            showIGRPMap : false
        }
    }

    RunResultadosZonas = () => {
        this.setState({
            showArea  : true
        });
    }

    ResetArea = () => {
        this.setState({
            showArea : false
        });
    }

    ToggleIGRPMap = (e)=>{
        e.preventDefault()
        this.setState( (oldState)=>{
            return {
                ...oldState,
                showIGRPMap : !oldState.showIGRPMap
            }
        });
        return false;
    }

    organizeZonas(zonas){
        let Zonas = [];
        const parents = zonas.filter(z=>z.cre === '');

        parents.forEach( (z)=>{
            const code = z.code;
            const childs = zonas.filter( c=>c.cre === code );
            if(parents.length > 1)
                Zonas.push( z )

            Zonas = Zonas.concat(childs);
        });

        return Zonas
    }

    render() {
        const { elections, regions, candidatos } = this.props;
        const selectedContext = 'results';
        const { isFetching }  = elections;
        const selectedRegion  = elections.selected;
        const chartsData      = elections.charts || {};
        const hasZones        = selectedRegion !=='nacional' && chartsData && chartsData.zonas  && chartsData.zonas[0];
        const selectedRegionItem = regions.data.filter( r=>(r.code === selectedRegion) )[0];
        const selectedName = selectedRegionItem ? selectedRegionItem.name : '';

        const series = elections.series;
        //const Zonas = hasZones ? this.organizeZonas(chartsData.zonas) : null;

        return (
            <>
                {!this.state.showIGRPMap &&(
                    <>
                    <Row className="row-wide-col">
                        <Col  lg="12">
                            <Candidates></Candidates>
                        </Col>
                    </Row>
                    <Row className="mt-2 position-relative w-100 mx-0">
                        <Col className="p-0">
                            <Row className=" h-100">
                                <Col md="6" lg="8"  className=" ">
                                    <Card className="shadow border-0 h-100 card-map bg-light">
                                        <Card.Body className="p-0 d-flex flex-column align-items-center">
                                            <div className="d-flex position-absolute align-items-center px-3 pt-3" style={{top:0,left:0,width:'100%',zIndex:402}}>
                                                <Mapcrumb></Mapcrumb>
                                                {hasZones && (
                                                    <div className="ml-auto ">
                                                        <button onClick={() => this.RunResultadosZonas()} className="btn btn-light btn-sm app-zonas-modal-btn d-flex align-items-center">
                                                            <i className="fas fa-list mr-1"></i><div className="d-inline small text-uppercase"><b className="">Zonas</b></div>
                                                        </button>
                                                    </div>
                                                )}
                                                <div className={ !hasZones ? 'ml-auto' : '' }> 
                                                    <NavLink to={`/${Config.mapURL}#`} className="btn btn-light btn-sm app-zonas-modal-btn small d-flex align-items-center">
                                                        <i className="fas fa-map-marker-alt mr-1"></i><div className="d-inline small text-uppercase"><b>Mesas</b></div>
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className="w-100 m-auto h-100">
                                                <MapLeaflet></MapLeaflet>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="6" lg="4"  className="">
                                    
                                    <Card className="card-white shadow border-md border-0 py-2">
                                        <Card.Body>
                                            <div className="">
                                                {chartsData.mesas    && (
                                                    <LineHorizontal 
                                                        title={ _('MESAS') } 
                                                        totalLabel="Total de Mesas"
                                                        titleDetail={ <div className="position-absolute"><Link to={`/${Config.mapURL}`} className="small app-line-h-pct">+ detalhes</Link></div>}
                                                        {...chartsData.mesas} 
                                                        />
                                                )}
                                            </div>
                                        </Card.Body>
                                    </Card>

                                    <Card className="card-white shadow border-md border-0 py-2 mt-md-4">
                                        <Card.Body>
                                            <div className="">
                                                {chartsData.votantes && 
                                                    <LineHorizontal 
                                                        totalDescription={ _('INSCRITOS') } 
                                                        title={ _('VOTANTES') } 
                                                        {...chartsData.votantes}
                                                    />
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>

                                    <Card className="card-white shadow border-md border-0 py-2 mt-md-4">
                                        <Card.Body >
                                            <div className="py-3">
                                                {chartsData.votantes &&
                                                    <SimpleStats
                                                        data={ [
                                                            {
                                                                pct : chartsData.votantes.abstention_pct,
                                                                value : chartsData.votantes.abstention,
                                                                title : _('ABSTENCAO'),
                                                            },
                                                            {
                                                                ...chartsData.nulos, 
                                                                title : _('NULOS'), 
                                                                color: '#bbb5b7' ,
                                                                align : 'center'
                                                            },
                                                            {
                                                                ...chartsData.brancos, 
                                                                title : _('BRANCOS'), 
                                                                color: '#8b8a90',
                                                                align : 'right'
                                                            }
                                                        ] }
                                                    />
                                                    }
                                            </div>
                                           </Card.Body>
                                    </Card>
                                </Col>
                                {
                                    /*
                                    <Col sm="6">
                                        {chartsData.genero && (
                                            <Genero data={chartsData.genero}/>
                                        )}
                                    </Col>
                                    <Col sm="6">
                                        {chartsData.faixa_etaria && (
                                            <FaixaEtaria data={chartsData.faixa_etaria}/>
                                        )}
                                    </Col>
                                    */
                                }
                                

                            </Row>
                            <Row>
                               
                            </Row>
                        </Col>
                    </Row>    
                    <Row>
                        <Col xl="12" >
                            <GoTop></GoTop>
                            <Footer></Footer>
                        </Col>
                    </Row>               
                    </>
                )}
                {this.state.showArea &&  hasZones &&
                    <ResultadosZonas {...this.state}
                        resetArea   = {this.ResetArea}
                        candidatos = {candidatos} 
                        regions    = {selectedName}
                        context    = {selectedContext}
                        zonas      = { this.organizeZonas(chartsData.zonas) }
                    />
                }
            </>
        )
    }
}

export default connect( (s)=>({...s}), (dispatch, props)=>{
    return {
        setContext : ()=>dispatch( setContext(props.selectedContext) )
    }
} ) (Dashboard ) ;