import { useState, useEffect } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useMap, GeoJSON } from "react-leaflet"
import { connect } from "react-redux"
import { fetchMesasData, fetchMesasRef } from 'store/gis/actions';
import { renderToStaticMarkup } from 'react-dom/server'

const Popup = (props)=>{

    return (
      <div className="app-mesas-popup">
        <div>
          <div className="d-block mb-2">{props.DESCRICAO} <br></br><b>{props.MES_REFERENCIA}</b> </div>
          <div className="mt-1 small">Clique no ponto para mais detalhes</div>
        </div>
      </div>
    )
}

const onEachFeature = (feature, layer) => {

    const popup = renderToStaticMarkup ( <Popup {...feature.properties} /> )
    
    layer.bindPopup(popup);

    layer.on('mouseover', function() { layer.openPopup(); });
    layer.on('mouseout', function() { 
        setTimeout( ()=>{
            layer.closePopup();
        },800 )
    });
}

const Layer = (props) =>{

    const { geojson } = props.gis.mesas;

    const { mesas } = props.gis.refMesas || [];

    const { withResults } = props;

    const closed = withResults ? ' closed' : '';

    const geoFilter = (feature) => {
        return mesas.includes(feature.properties.MES_REFERENCIA)
    };

    const geoFilterWithOutResults = (feature) => {
        return !mesas.includes(feature.properties.MES_REFERENCIA)
    };
    
    return (
        <MarkerClusterGroup
            spiderfyOnMaxZoom = {true}
            showCoverageOnHover = {true}
            zoomToBoundsOnClick = {true}
            id={`app-markers-cluster-${closed}`}
            //disableClusteringAtZoom={15}
            maxClusterRadius={80}
            iconCreateFunction = {(cluster)=>{
                const L = window.L;
                const count  = cluster.getChildCount();
                const digits = (count + '').length;
            
                return L.divIcon({
                    html: count,
                    className: 'cluster blinker digits-' + digits + closed,
                    iconSize: null
                });
            }}
            >                
                <GeoJSON 
                    id={`app-data-json-${closed}`}
                    key={`app-data-json-${closed}`}
                    data={ geojson }
                    filter= { withResults? geoFilter : geoFilterWithOutResults} 
                    eventHandlers={{
                        click: (e) => {
                           props.markerClick(e)
                        },
                    }}
                    onEachFeature={ onEachFeature }
                    pointToLayer={ ( _feature, latlng ) =>{
                        const L         = window.L;
                        const icon = new L.DivIcon({
                            html: '<i class="fas fa-map-marker-alt"></i>',
                            className: 'text-center app-map-icon ' + closed,
                            iconSize: new L.Point(40, 40)
                        });
                        return L.marker(latlng, {icon: icon});
                    } }
                >
                
                </GeoJSON>
          
        </MarkerClusterGroup>
    )
}

const MapClusters = (props)=>{

    const regions = props.regions.data;

    const { version }  = props.elections.update;
    
    const { mesas } = props.gis.refMesas || [];

    const { showMesasWithResults } = props.gis;

    const { showMesasWithOutResults } = props.gis;

    const map = useMap();

    useEffect(() => { 

        props.dispatch ( fetchMesasData(props) );

    }, [regions, version])
    
    return mesas[0] && props.gis.mesas.geojson ?  (
        <>
            {showMesasWithResults && <Layer {...props} withResults={true}/> }
            {showMesasWithOutResults && <Layer {...props} withResults={false}/> }        
        </>
    ) : null
}

export default connect( (s)=>({...s}))(MapClusters)