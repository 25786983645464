import './Map.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import { Circle } from 'react-leaflet'
import { fetchData } from "store/elections/actions";
import { useEffect, useRef } from 'react';

const style    = {
    color : 'rgba(0,0,0,.2)',
    strokeOpacity : .4,
    weight:1,
    fillColor:'white',
    fillOpacity:.5
}

const Nacional = connect(s=>({...s}))( (props)=> {

    const selected = props.elections.selected;
    const series   = props.elections.series;
    const nacional = series[0] ? series.filter( s=>s.key === 'nacional' )[0] : null;
    const ref = useRef();
    
    useEffect(()=>{
        if(nacional && nacional.color){
            const color = nacional.color;
            if( selected === 'global'){
                style.color = color;
                style.fillColor = color;
                ref.current.setStyle(style)
            }
        }
    },[series])
    
    
    return selected === 'global' ? (
        <Circle
            ref={ref}
            id="cv-geo"
            center={[16.02822, -23.868417 ]}
            radius={500000}
            eventHandlers={{
              click: () => {
                props.dispatch( fetchData('nacional') )
              },
            }}
            {...style}
        />
    ) : (<></>)
  });

  export default Nacional;