import React, {  useState } from 'react';
import {Modal, Col, Row} from 'react-bootstrap'
import {UpperToCapitalize, ZeroPad, uid, imgPath} from 'utils'
import { connect } from 'react-redux';

import {  hideElectedModal } from  'store/elections/actions'

const ModalElected2 = ({elections,deputados, regions,candidatos, dispatch})=>{
    
    const electedModalData = elections.electedModalData || {
        data : [],
        id : null
    };

    const [ search, setSearch ] = useState("");
    
    const selectedRegion = regions.data.filter( (r)=>r.code === elections.selected )[0] || {}

    const electedNames = electedModalData.data.map( i=>{
        const deputado = deputados.filter( d=> d.id === i )[0] || {
            "id": 0,
            "name": "Não Indicado",
            "image": "jon_doe.jpg",
            "ordem": 1,
            "circulo": "",
            "partido": electedModalData.id,
            "biography": "",
            "birth_date": ""
        };
        return deputado
    });

    const onModalClose = ()=>{

        dispatch( hideElectedModal() );

        setSearch('');
    }

    return(
        <Modal  
        size="lg"
        show={ electedNames[0] ? true : false }
        onHide={ onModalClose }
        aria-labelledby="">
            <Modal.Header closeButton className="border-0 position-sticky top-0 bg-white" style={ {zIndex:10} }>
                <div className="modal-title w-100">
                    <Row className=" align-items-center">
                        <Col lg="5" md="12">
                            <div className="text-uppercase text-dark small">{selectedRegion.name}</div>
                            <div className="mb-0 font-weight-bold h6 modal-title-holder">
                                Deputados Eleitos 
                                <div className="text-uppercase font-weight-bold mt-2">{electedModalData.id}</div>
                            </div>
                        </Col>
                        {electedNames.length > 10 && 
                            <Col  lg="7" md="12" className="mt-3 mt-lg-0">
                                <div className="position-relative">
                                    <input type="text" className="form-control form-control-sm pr-4" value={search} onChange={ (e)=>{
                                        setSearch(e.target.value)
                                    } } placeholder={'Pesquisar Deputado'}/>
                                    <i className="fas fa-search position-absolute text-mute icon-search"></i>
                                </div>
                            </Col>
                        }
                    </Row>
                   
                </div>
            </Modal.Header>
            <Modal.Body>
                {electedNames.filter( item=> {
                    return item.name.toUpperCase().includes(search.toUpperCase()) 
                }).map( (item,i)=>{
                    const partido = candidatos.filter( c => ( c.id === item.partido ) )[0] || false;
                    return (
                        <div key={i+'-'+uid()} className="position-relative mb-3 pt-3 holder-elected-candidates d-flex align-items-center" >
                            {partido && (
                                <div className="order-candidato" circulo-active={electedModalData.id ? "partido" : "nacional"}>
                                    {!electedModalData.id && 
                                        <>
                                            <img alt={item.partido} src={imgPath(partido.image)} className="app-voto-candidato"/>
                                            <span className="align-middle ml-0 ml-md-2 mt-1 mt-md-0 font-weight-bold party-name d-block d-md-inline-block">{item.partido}</span>
                                        </>
                                    }
                                    {electedModalData.id &&
                                        <>
                                        <span className="align-middle text-center font-weight-bold party-name d-block">{ZeroPad(item.order)}</span>
                                        </>
                                    }
                                </div>
                            )}
                            <div className="info-candidates d-flex ml-3 align-items-center" circulo-active={electedModalData.id ? "partido" : "nacional"}>
                                <div className="app-elected-cadidate-pic">
                                    <img alt={item.name} className="" src={ imgPath(item.image) } />
                                </div>
                                <div className="pl-3 w-100">
                                    {!item.biography && <>
                                        <span className="info-candidates-name d-block text-capitalize font-weight-normal fs-12">{UpperToCapitalize(item.name)}</span>
                                       
                                        <span className="info-candidates-circle d-block font-weight-bold fs-11 small text-uppercase">{item.circulo}</span>
                                      
                                        
                                    </>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Modal.Body>
        </Modal>
    )
}

export default connect( (s)=>({...s}) )(ModalElected2)
