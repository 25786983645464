import React, { Component } from 'react';
import _ from '../Translate';
import CloneDeep from 'lodash/cloneDeep';
import {Modal, Col, Row} from 'react-bootstrap'
import {UpperToCapitalize, ZeroPad,ReplaceSpecialChars, uid, imgPath} from 'utils'

export default class ModalElected extends Component {
    constructor(props){
        super(props)
        
        this.state = {
            activeItem : "",
            searchResult : CloneDeep(this.props.elected)
        }

    
    }

    infoBoxClick = (id) => {
        this.setState({
            activeItem : this.state.activeItem !== id ? id : null
        })
        return;
    }

    handleonKeyUp = (e) => {

        let value = e.target.value;

        let elected = value  ? this.props.elected.filter( item=>( ReplaceSpecialChars(item.name).includes( ReplaceSpecialChars(value) ) ) ) : this.props.elected;

        this.setState({ 
            searchResult : elected
        });
    }

    render() {

        const context = this.props.context === 'camara' ? _("VERIADORES") : _("DEPUTADOS");
       
        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={() => this.props.hideModal(false)}
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton className="border-0 position-sticky top-0 bg-light shadow" style={ {zIndex:10} }>
                    <div className="modal-title w-100">
                        <Row className=" align-items-center">
                            <Col lg="5" md="12">
                                <div className="mb-0 font-weight-bold h6 modal-title-holder">{context+' '+_("ELEITOS")} <span className="candidates">{this.props.id}</span>
                                    <div className="text-uppercase regions font-weight-bold">{this.props.regions}</div>
                                </div>
                            </Col>
                            {this.props.elected.length > 10 && 
                                <Col  lg="7" md="12" className="mt-3 mt-lg-0">
                                    <div className="position-relative">
                                        <input type="text" className="form-control form-control-sm pr-4" onKeyUp={this.handleonKeyUp} placeholder={'Pesquisar '+context+' ...'}/>
                                        <i className="fas fa-search position-absolute text-mute icon-search"></i>
                                    </div>
                                </Col>
                            }
                        </Row>
                    </div>
                </Modal.Header>

                <Modal.Body className="pt-0">
                    {!this.state.searchResult[0] && (
                        <div className="small">Nenhum resultado...</div>
                    )}
                    {this.state.searchResult.map( (item,idx)=>{
                           
                        const partido = this.props.partidos.filter( c => ( c.id === item.partido ) )[0] || {};
            
                        return (
                            <div key={uid()} className="position-relative mb-3 pt-3 holder-elected-candidates" active-item={this.state.activeItem === idx ? 'active' : 'noactive'}>
                                <div className="position-absolute order-candidato" circulo-active={this.props.id ? "partido" : "nacional"}>
                                    {!this.props.id && 
                                        <>
                                            <img alt={item.partido} src={imgPath(partido.image)} className="app-voto-candidato"/>
                                            <span className="align-middle ml-0 ml-md-2 mt-1 mt-md-0 font-weight-bold party-name d-block d-md-inline-block">{item.partido}</span>
                                        </>
                                    }
                                    {this.props.id &&
                                        <span className="align-middle text-center font-weight-bold party-name d-block">{ZeroPad(item.order)}</span>
                                    }
                                </div>
                                <div className="info-candidates d-flex" circulo-active={this.props.id ? "partido" : "nacional"}>
                                    <div className="app-elected-cadidate-pic">
                                        <img alt={item.name} className="" src={imgPath(item.image)} />
                                    </div>
                                    <div className="pl-3 w-100">
                                        <span className="info-candidates-name d-block text-capitalize font-weight-normal fs-12">{UpperToCapitalize(item.name)}</span>
                                        <span className="info-candidates-circle d-block font-weight-bold fs-11">{item.circulo}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </Modal.Body>
            </Modal>
        )
    }
}
