import './Sidebar.css';
import React from  'react';
import { connect } from 'react-redux'
import { Col } from 'react-bootstrap';
import Logo from 'images/logo.png'
import { Accordion, NavLink, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { fetchData, setRegionData } from 'store/elections/actions'

import { NavLink as Link } from 'react-router-dom'

import { getRegionName, sortByKey, uid } from 'utils'
import { selectCre, selectCre2 } from 'store/gis/actions';
import { Adapter } from '../DataAdapter';

const ClosedToolip = ()=>(
  <OverlayTrigger
    placement="right"
    style={{zIndex:150000}}
    overlay={<Tooltip className="small">100% das mesas apuradas</Tooltip>}>
      <i className="far fa-check-circle app-circulo-closed"></i>
  </OverlayTrigger>
)

const Sidebar = (props)=>{
    const { data } = props.regions;
    const parents   = data.filter( d=>( d.parent_code === '' ) );
    const navClss   =  props.visible ? 'sidebar-visible' : 'sidebar-hidden';
    const fetchData = props.fetchData;
    const { selected,update, closedCircles } = props.elections;

    const isClosed = (code)=>{
      const circulo = closedCircles?.filter(m=>m.key === code)[0] ;
      return circulo && circulo.pct ? circulo.pct === 100 : false;
    }

    return (
        <Col id="sidebar" className="text-center">
            <div className="logo-holder" style={{ paddingTop:50,paddingBottom:50 }}>
                <Link to="/">
                    <img src={Logo}></img>
                </Link>
            </div>
            <Accordion className="mb-4">
            {parents.map( (r,i)=>{
                const childs    = data.filter( d=>( d.parent_code === r.code && d.code !== r.code) );
                const hasChilds = childs.length ? true : false;
                return (
                <div className="position-relative" key={`parent-${r.code}-${i}-${uid()}`}>
                    <Accordion.Toggle as={NavLink} variant="link"  eventKey={r.code} className={`text-left w-100 position-relative ${ r.code === selected ? 'active' : '' }`} onClick={  ()=>{ fetchData( r.code, update.version ) } }>
                        <div className="img-holder">
                            <img alt={r.code} className="app-region-img" src={ `/images/circulos/${r.code}.png` }></img>
                        </div>
                        <span className="app-region-name text-truncate text-uppercase" style={{fontWeight:500}}>{r.name}</span>
                        {isClosed(r.code) ? ( 
                            <ClosedToolip/>
                        ) : <></>}
                    </Accordion.Toggle>
                    { hasChilds ? (
                    <div className="ml-0 childs-holder">
                        {  childs.map(c=>(
                            <div key={c.code+'-'+uid()}>
                            <NavLink  className={`position-relative  text-left  app-region-child text-uppercase ${ c.code === selected ? 'active' : '' }`} variant="link" onClick={ ()=>{ fetchData( c.code, update.version ) } }>
                            <div className="img-holder">
                                <img alt={c.code} className="app-region-img" src={ `/images/circulos/${c.code}.png` }></img>
                            </div>
                            <small className="text-truncate  app-region-name text-capitalize">{ c.name.toLowerCase() }</small>
                            { isClosed(c.code) && ( <ClosedToolip/> ) }
                            </NavLink>
                            { c.code.toLowerCase() === props.gis.cre?.geojson?.name?.toLowerCase() && (
                                props.gis.cre?.geojson?.features?.map( (f,x)=>{
                                    const _code = f.properties.code;
                                    const hasData = props.elections.data.zones?.filter( z=>z.code?.toLowerCase() === _code?.toLowerCase() )[0];
                                    
                                    if( hasData && _code && _code.toLowerCase() != 'au')
                                        return (
                                            <NavLink 
                                                key={x+'-'+_code+'-'+uid()} 
                                                className={`region-cre position-relative small text-left  app-region-child text-capitalize ${_code.toLowerCase() === props.elections.region?.code ? 'selected' : ''}`} 
                                                code={ _code }
                                                variant="link" 
                                                onClick={ ()=>{
                                                    props.dispatch( selectCre(_code, f.properties) );
                                                 } }>
                                                    { getRegionName( _code, f.properties.name?.toLowerCase() )  }
                                                    { hasData.pct_mesas_apuradas === 100 &&  <ClosedToolip/>}
                                            </NavLink>
                                        )
                                    else 
                                        return null;
                                } )
                            )}
                            </div>
                        ))}
                    </div>
                    ) : (<></>)}
                    </div>
                )})}
            </Accordion>
        </Col>
    )
}

const mapStateToProps = (s)=>({...s});

const mapDispatchToProps = (dispatch, props) => {
  return {
      dispatch : dispatch,
      //selectCre : (data)=>dispatch( selectCre(data) ),
      setRegionData : (data,properties)=>dispatch( setRegionData(data,properties) ),
      fetchData: (code,version) => {
        const _r = dispatch( fetchData( code,version ) );
        _r.then( ()=>{
          if(props.afterFetch)
            props.afterFetch();

            //dispatch( selectCre(null,null) )
        });
        return _r
      }
  };
};


export default connect( mapStateToProps, mapDispatchToProps )(Sidebar) 