
import { connect } from 'react-redux'
import {useMap,GeoJSON } from 'react-leaflet'
import { setPostoData, setRegionData, showElectedModal, toggleCre } from 'store/elections/actions'
import { HiddenMesas } from '.'
import { useEffect, useState } from 'react'
import { fetchData } from 'store/elections/actions'
import { selectCre, setCreGeo } from 'store/gis/actions'
import { Adapter } from 'components/v2/DataAdapter'
import axios from 'axios'
import Centers from 'resources/gis/centers.json'
import _ from 'components/Translate'
import { capitalize2, getRegionName } from 'utils'
import Postos from './Postos'

const Regions = (props)=>{
    
    const data = props.gis.cre.geojson;
    //const [data, setData]     = useState(null);
    const [postos, setPostos]     = useState(null);
    const [ showModal, setShowModal ] = useState(false);
    const map                 = useMap();
    const selected            = props.elections.selected;
    const showCre             = props.elections.showCre;
    const showControl         = !HiddenMesas[selected];
    const zoneData            = props.elections.data.zones; 

    const isTabletOrMobile    = ()=> window.innerWidth <= 992;

    const toggleModal = (e)=>{
        if(isTabletOrMobile()){
            e.preventDefault();
            setShowModal(window.scrollY > document.querySelector('#ranking')?.getBoundingClientRect()?.height );
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', toggleModal);
        return ()=> window.removeEventListener('scroll', toggleModal);
    },[]);

    const setFeatureColor = (f)=>{

        const zones      = props.elections.data.zones;
        const code       = f?.properties.code;
        const name       = f?.properties.name; 
        const regionName = props.elections.regionName;

        let style        = {
            fillOpacity: regionName ? .2 : 1,
            color:'rgba(0,0,0,.25)',
            weight : .6,
            fillColor : 'transparent',
        };

        if(code && zones && zones[0]){
            const zoneData   = (zones.filter( (z)=>z.code?.toLowerCase() === code.toLowerCase() ))[0];
            const candidateId = zoneData?.votes[0].id;
            if(zoneData?.color){
                style.fillColor = zoneData.color 

                if(regionName === getRegionName( code, name ))
                    style.fillOpacity = 1
            }
                
        }
        //console.log(style)
       return style;
    }
    
    useEffect( ()=>{
        //setData(null);

        props.dispatch( setCreGeo(null) );
      
        if( showCre && !HiddenMesas[selected] && Centers[selected]?.cre )
            axios.get('/gis/cre/'+selected+'.json').then(geo => {
                props.dispatch( setCreGeo(geo.data) );
                //setData(geo.data);
            });
        
    },[selected]);

    useEffect( ()=>{
        setPostos(null);
    },[props.elections.regionName] )


    return (
        <>

        {showControl && data && (
            <GeoJSON 
                data={data}
                onEachFeature={(feature,layer)=>{
                    if(feature.properties.code && feature.properties.name)
                    layer.bindTooltip(
                        '<div class="text-capitalize">'+(getRegionName(feature.properties.code, feature.properties.name))?.toLowerCase()+'</div>' , {
                        sticky:true,
                        direction:'center',
                        className: 'countryLabel'
                    });  
                }}
                eventHandlers={{
                    tooltipopen:(e)=>{
                        const layer   = e.layer;
                        const feature = layer.feature;
                        if( props.elections.region?.code == feature.properties.code   )
                            e.tooltip._close()
                    },
                    click:(e)=>{
                        const layer      = e.layer;
                        const feature    = layer?.feature;
                        const properties = feature?.properties;
                        const code       = properties.code;
                        const parent     = selected;
      
                        if(code)
                            props.dispatch( selectCre( code, properties ) );

                        else
                            props.dispatch( fetchData( parent ) );
                        
                      
                    }
                }}
                style={(feature)=>{
                    return setFeatureColor(feature);
                }}
            />
        )}

        {/*
            postos && (
                <Postos data={postos}></Postos>
            )
        */}
        </>
    )
}

export const SelectRegion = ()=>{

}


export default connect( (s)=>({...s}) )(Regions) 