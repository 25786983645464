import Config from 'config'
import  { SET_REGIONS, SELECT_REGION, FETCHING } from './action-type'

const initialState = {
    selected : {},
    data     : [],
    isFetching : true
};


export default (state = initialState, action) => {

    const defaultRegionCode = (
        Config.defaults && 
        Config.defaults.regions && 
        Config.defaults.regions.selected ? Config.defaults.regions.selected : "global"
    );
    
    if(action.type === SET_REGIONS){
     
        return {
           selected : action.data.filter( r=>(  r.code === defaultRegionCode  ) )[0] || action.data[0],
           data     : [...action.data],
           isFetching : false
        }
    }

    if(action.type === SELECT_REGION){
        return {
            ...state,
            selected : action.data
        }
    }

    if(action.type === FETCHING){
     
        return {
            ...state,
            //isFetching : true
        }
    }

  return state;

};