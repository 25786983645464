import React, { useEffect, useRef } from 'react'
import { MapContainer,TileLayer, Popup, Marker, GeoJSON, useMap, Circle } from 'react-leaflet'
import { connect } from 'react-redux'
import { fetchData, setRegion } from "store/elections/actions";
import Centers from 'resources/gis/centers.json'
import basemap from 'resources/gis/basemap.json'

const MapLocation = connect((s)=>({...s}))( (props)=> {
  const map  = useMap();
  let center = Centers[props.elections.selected] ? Centers[props.elections.selected].center : Centers.global.center;
  let zoom   =  Centers[props.elections.selected] ? Centers[props.elections.selected].zoom : Centers.global.zoom;
 
  map.flyTo(center, zoom, {
    animate:false,
  });

  map.scrollWheelZoom.disable()
  
  return null;
});

const Nacional = connect(s=>({...s}))( (props)=> {
  const selected = props.elections.selected;
  const series   = props.elections.series;
  const nacional = series[0] ? series.filter( s=>s.key === 'nacional' )[0] : null;
  const style    = {
      color : 'rgba(0,0,0,.2)',
      strokeOpacity : .4,
      weight:1,
      fillColor:'white',
      fillOpacity:.5
  }
  if(nacional && nacional.color){
      const color = nacional.color;
      if( selected === 'global'){
          style.color = color;
          style.fillColor = color;
      }
  }
  
  return selected === 'global' ? (
      <Circle 
          id="cv-geo"
          center={[16.02822, -23.868417 ]}
          radius={500000}
          eventHandlers={{
            click: () => {
              props.dispatch( fetchData('nacional') )
            },
          }}
          {...style}
      />
  ) : (<></>)
});

export default connect((s)=>({...s}))( (props)=>{
  let center  = Centers[props.elections.selected]?.center || Centers.global.center;
  let zoom    = Centers[props.elections.selected]?.zoom  ||  Centers.global.zoom;
  return (
      <MapContainer 
        center={center} 
        zoom={zoom} 
        zoomControl={false} 
        scrollWheelZoom={true} 
        style={{height:'100%'}}>
          <GeoJSON data={basemap}/>
          
          <MapLocation></MapLocation>
          <Nacional/>
      </MapContainer>
  )
});
