import React from 'react'
import { Card,  OverlayTrigger, Tooltip } from 'react-bootstrap'
import { format } from 'utils'

export default (props)=>{
    
    const clss = props.className || '';
    const transparent = props.transparent === false ? false : true;
    const transparentClss = transparent ? 'card-transparent' : '';
    const sizes = props.sizes || {};
    const colClss = props.colClass || 'col';

    return (

       <Card className={` ${clss} ${transparentClss} app-simple-stat `}>
           <Card.Body className="">
                <div className="row">
                    {
                        props.data && props.data[0] && (
                            props.data.map( (item,i)=>{
                                const style = {
                                    //color : item.color,
                                    textAlign : item.align,
                                    margin : item.margin,
                                    fontWeight:500
                                };
                                return(
                                    <OverlayTrigger
                                        key={i}
                                        placement="top"
                                        trigger="click"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={<Tooltip >{item.title}</Tooltip>}
                                    >
                                    <div  {...sizes} className={ `${colClss} col-sm-4 my-3 app-simple-stat-item text-lg-center ` } data-title={item.title} style={ style }>
                                        
                                        <div className="app-simple-stat-title card-title text-truncate font-weight-bold" style={ {fontSize:14, fontWeight:600} }>
                                            <span>{item.title}</span>
                                        </div>
                                        
                                        <div className="app-simple-stat-value ">{format(item.value)}</div>
                                        <div className="app-simple-stat-pct text-baby-blue font-weight-bold">{item.pct}%</div>
                                    </div>
                                </OverlayTrigger>
                            ) })
                        )
                    }
                </div>
           </Card.Body>

       </Card>

    )
}