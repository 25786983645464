import axios from 'axios'

import { dateStringToObject, timeStringToObject } from 'utils'

export const setTime = (data) => ({
    type: "SET_TIME", 
    data
});

export const tick = (startTime) => ({
    type: "TICK",
    startTime
});

export const is_fetching = () => ({
    type: "FETCHING"
});


export const setCurrentTime = (data) => ({
    type: "SET_CURRENT_TIME",
    data
});

export const fetchTime = ()=>{
   

    return (dispatch)=>{

        dispatch(

            is_fetching()

        );

        const startTime = new Date();

        let requestDataV  = axios.get( '/data/version.json?v='+startTime.getTime() );

        axios.all([requestDataV]).then(axios.spread((...responses) => {
            
            const resDataV  = responses[0];
            const resultsDate = dateStringToObject(resDataV.data.date);
            const resultsTime  = timeStringToObject(resDataV.data.time);
            const status       = resDataV.data?.status_site == 1;

            setTimeout(()=>{

                dispatch(

                    setTime( 
                        {
                            status,
                            lastUpdate : new Date(
                                resultsDate.year,
                                resultsDate.month,
                                resultsDate.day,
                                resultsTime.hours,
                                resultsTime.minutes,
                                resultsTime.seconds
                            )
                        }
                        
                    )

                );

            }, 1000)
       
          }))
        
    }

}
