import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import "core-js/stable";

import "regenerator-runtime/runtime";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
window._start_rendering = new Date().getMilliseconds();

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LekA8scAAAAAPfjiMCVvBc51HPs7HdQypiov3ra"
    language="pt"
    //useRecaptchaNet="[optional_boolean_value]"
    //useEnterprise="[optional_boolean_value]"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'head', // optional, default to "head", can be "head" or "body",
      nonce: undefined // optional, default undefined
    }}
  >
   <App />
  
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
