import React from 'react';

import { Row, Col, Card} from 'react-bootstrap'

import _ from 'components/Translate'
import { LineHorizontal } from '.';
import { connect } from 'react-redux';
import SimpleStat from './SimpleStat';
import { imgPath } from 'utils';


const MesaDetails = ( {mesa , data, candidatos , prefix} )=>{
    const getCandidato = (id)=>{
        return candidatos.filter( p=> id.toUpperCase() === p.id.toUpperCase() )[0] 
    }
    const pfx = prefix || "";

    const params = {
        vontantes : {
            value : data['votantes_value'+pfx],
            pct   : data['votantes_pct'+pfx]
        },

        protestados:{
            value : data['protestados_value'+pfx],
            pct   : data['protestados_pct'+pfx]
        },

        validos:{
            value : data['validos_value'+pfx],
            pct   : data['validos_pct'+pfx]
        },

        brancos:{
            value : data['brancos_value'+pfx],
            pct   : data['brancos_pct'+pfx]
        },

        abstencao:{
            value : data['abstencao_value'+pfx],
            pct   : data['abstencao_pct'+pfx]
        },

        nulos:{
            value : data['nulos_value'+pfx],
            pct   : data['nulos_pct'+pfx]
        }
    }

    
    const voteValueParam = 'votes'+pfx
    const votes = data[voteValueParam] || [];

    return (
        <div className="py-4 app-mesas-details-wrapper stats" >
            
            <Row className="m-0 row-smaller-dist flex-lg-nowrap">
                <Col md="12" lg="auto" >
                    <LineHorizontal 
                        totalDescription={ _('INSCRITOS') } 
                        title={ _('VOTANTES') } 
                        value= { params.vontantes.value }
                        pct = { params.vontantes.pct }
                        total = { data.total_inscritos }
                    />
                </Col>
                <Col md="12" lg="auto" className="mesas-details-extra gis-map">
                   <SimpleStat
                        
                        transparent={false}
                        colClass = 'col-6 col-lg-auto'
                   
                        data={ [
                            {
                                pct : params.validos.pct,
                                value : params.validos.value,
                                title : 'Válidos',
                                align:'left',
                                margin: '0 0 15px 0'
                            },
                            {
                                pct : params.protestados.pct,
                                value : params.protestados.value,
                                title : 'Protestados',
                                color : '#bbb5b7',
                                align:'center',
                                margin: '0 0 15px 0'
                            }
                        ] }
                    />
                </Col>
                <Col md="12" lg="auto" className="mesas-details-extra gis-map">
                    <SimpleStat
                       
                        transparent={false}
                        colClass = 'col-4 col-lg-auto'
                   
                        data={ [  
                            {
                                pct : params.abstencao.pct,
                                value : params.abstencao.value,
                                title : _('ABSTENCAO'),
                                margin: '0 0 15px 0'
                            },                          
                            {
                                pct : params.nulos.pct,
                                value : params.nulos.value,
                                title : _('NULOS'), 
                                color: '#bbb5b7' ,
                                align : 'center',
                                margin: '0 0 15px 0'
                            },
                            {
                                pct : params.brancos.pct,
                                value : params.brancos.value,
                                title : _('BRANCOS'), 
                                color: '#8b8a90',
                                align : 'right',
                                margin: '0 0 15px 0'
                            }
                        ] }
                    />
                </Col>
            </Row>
            <Row className="mt-4 mx-0 row-candidates">
                <Col>
                    <div id="votes-table" >     
                    {votes.map( (v,i)=>{        
                        const candidato = getCandidato(v.id) || {};
                        return  (       
                            <div key={v.id+'-'+i} className="d-lg-flex mb-5 mb-lg-4 align-items-center text-blue">
                                <div className="d-flex align-items-center col" >
                                    <div style={{ 
                                        backgroundImage:`url( /images/candidatos/${v.id?.toLowerCase()}.jpg )` , 
                                        minWidth:45, 
                                        height:55, 
                                        backgroundSize:'cover',
                                        backgroundPosition:'top'
                                    }}/>
                                    <div className="ml-3 text-blue w-100 vote-item-name" style={{minWidth:140, maxWidth:140}}>
                                        <span className="text-capitalize truncate-2 font-weight-500" style={{fontSize:15}}>{candidato.name?.toLowerCase()}</span>
                                    </div>
                                </div>
                                <div className="ml-lg-3 mt-2 mt-lg-0 col">
                                    <div className="d-flex align-items-center">
                                        <b>{v.pct}%</b>
                                        <div className="box-bar d-flex ml-3" style={{width:90}}>
                                            <div className="box-bar-color shadow" style={{backgroundColor:candidato.color}}></div>
                                            <div className="box-bar-wrapper">
                                                <div className="box-bar-pct" style={{backgroundColor:candidato.color, width:`${v.pct}%`}}></div>
                                            </div>
                                        </div>
                                        <div><span className="font-weight-500">{ v.votes }</span> <small>votos</small></div>
                                    </div>
                                    
                                </div>
                            </div>
                        )
                     })}
                    </div>
                    {/* <div className="d-flex flex-column flex-lg-row">
                        {votes.map( (partido,i)=>{
                            const partyData = getPartido(partido.id) || {};
                            return  (
                                <div className="d-block text-left text-lg-center align-items-center d-flex d-lg-block my-3" key={partido.id} style={ {} }>
                                    <div className="p-2 m-lg-auto" style={ { height:40 } }>
                                        <img alt={partido.id} src={ partyData.image } style={ { maxWidth:'none', height:30, width:30 } }></img>
                                    </div>
                                    <div className="p-2" style={{width:80}}>
                                        <div className="text-uppercase small"><b>{partido.id}</b></div>
                                    </div>
                                    <div className="p-2">
                                        {partido.votes}
                                    </div>
                                </div>
                            ) 
                        })}


                    </div> */}
                </Col>
                
            </Row>

        </div>
    )
}

export default connect( (s)=>({...s}) )(MesaDetails)

