
import { connect } from 'react-redux'
import { toggleCre } from 'store/elections/actions'
import { HiddenMesas } from '.'
import Centers from 'resources/gis/centers.json'
import _ from 'components/Translate'
import { Accordion, Card, Col, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { imgPath, uid, format } from 'utils'

const PostosModal = (props)=>{

    const [ postosData, setPostosData ] = useState([]);
    const [ viewModal, setViewModal ] = useState(false);
    const selected        = props.elections.selected;
    const regionSelected  = props.elections.region.code;
    const showBtn         = (!HiddenMesas[selected] && !Centers[selected]?.cre) || props.elections.regionName;
    let postos            = props.elections.data.postos || [];

    postos = Centers[selected]?.cre ? postos.filter( p=>p.cre?.toLowerCase() === regionSelected?.toLowerCase() ) : postos;

    const getCandidato = (id)=>{
        return props.candidatos.filter( item=>( item.id.toLowerCase() === id.toLowerCase() ) )[0] || {};
    }

    const getRegionByID = (id)=>{
        if(id)
            return  props.regions.data?.filter( r=>r.code?.toUpperCase() === id?.toUpperCase() )[0];
    }
    
    useEffect( ()=>{
        
        setPostosData(postos);

    },[regionSelected, selected] )

    const FirstRowHeader = ()=>{

        let firstRow = null;

        if(postosData && postosData[0] && postosData[0].votos)
            firstRow = postosData[0].votos;

        return (
            firstRow && firstRow.map(c => {
                return (
                    <th className="border-0 text-center p-2 pb-4 small align-middle" key={uid()} style={{width:100}}>
                        <div className="text-center app-voto-candidato d-block mb-2 mx-auto">
                            <img alt={c.id} src={ `/images/candidatos/${c.id?.toLowerCase()}.jpg` }/>
                        </div>
                        <span className="candidates text-uppercase truncate-1">
                            {getCandidato(c.id)?.name?.toLowerCase()}
                        </span>
                    </th>
                )
            })
        )
    }

    const FirstRow = ({zone})=>{
        let firstRow = null;
        if(postosData && postosData[0] && postosData[0].votos)
            firstRow = postosData[0].votos;
        return (
            firstRow && firstRow.map(zv => {
                const zona = zone.votos.filter(zp => zp.id === zv.id)[0];
                return (
                    <td className="border-0 text-center p-2 align-middle holder-highlight" key={uid()}>
                        {
                            zona && <>
                                {zona.highlight === 1 && <span highlight={zona.id} className="px-2 py-1 area-highlight text-nowrap rounded text-white font-weight-bold d-inline-block" style={{ minWidth:"50px", background:`${getCandidato(zv.id).color}`}}>{format(zona.value)}</span>}
                                {zona.highlight === 0 && <span className="px-2 py-1 text-nowrap">{format(zona.value)}</span>}
                            </>
                        }
                    </td>
                )
            })
        )
    }

    return showBtn ? (
        <>
        <div className="px-3 py-2 small font-weight-600 border-1 shadow cursor-pointer" style={{borderRadius:10,background:'#DCDCDC'}} onClick={ ()=> {
            setViewModal(true)
        } }>
            <i className="fas fa-list  mr-2"></i> Zonas
        </div>


        <Modal show={viewModal} onHide={ ()=>setViewModal(false) } id="postos-modal">
            <Modal.Header closeButton className="border-0 position-sticky top-0 bg-white pb-0" style={ {zIndex:10} }>
                <div className="modal-title w-100">
                    <Row>
                        <Col  lg="12" md="12">
                            <div style={{lineHeight:'20px'}} className="mb-2 font-weight-400 text-blue h6 modal-title-holder">Resultados por zonas <span className="candidates text-capitalize"></span>
                                <div className="text-uppercase regions font-weight-bold" style={{fontSize:14,}}>
                                    <span>{ props.elections.regionName || getRegionByID(selected)?.name }</span>
                                </div>
                            </div>
                        </Col>
                        <Col  lg="12" md="12" className="mt-3 mt-lg-0">
                            <div className="position-relative my-2">
                                <input 
                                style={{
                                    borderRadius:10
                                }}
                                onChange={(e)=>{
                                    var val = e.target.value;
                                    if(val.length >= 0){

                                        var filter = postos.filter( p=>{
                                            return p.nome?.toLowerCase().indexOf( val?.toLowerCase() ) >= 0;
                                        } )
    
                                        setPostosData(filter);

                                    }else{
                                        setPostosData(postos);
                                    }
                                    

                                }} type="text" className="form-control form-control-sm px-4 py-3" placeholder={'Pesquisar Zona...'}/>
                                <i className="fas fa-search position-absolute text-mute icon-search"></i>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Modal.Header>
            <Modal.Body className="pt-0" >
               
                <Table className="border-0 m-0" hover size="sm">
                    <thead className="pb-3">
                        <tr>
                            <th className="border-0 p-2 pb-4 mxw-50 align-middle" style={{minWidth:190}}>
                                <div className="icons-legend d-flex">
                                    <div className="mr-3 mt-2">
                                        <span className="d-block small">{" < 50% "}</span>
                                        <div className="zonas-legend status-1"></div>
                                    
                                    </div>
                                    <div className="mx-3 mt-2">
                                        <span className="d-block small">{"> 50%"}</span>
                                        <div className="zonas-legend status-2"></div>
            
                                    </div>
                                    <div className="ml-3 mt-2">
                                        <span className="d-block small"> 100% </span>
                                        <div className="zonas-legend status-0"></div>
                                    </div>
                                </div>
                                <span className="small font-weight-500 pt-3 d-block">Mesas Apuradas</span>    
                            </th>
                            <FirstRowHeader></FirstRowHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            postosData.map(z => {
                                const clss = z.cre === '' ? 'bg-light ' : '';
                                const border = z.cre === '' ? 'border-bottom' : '';
                                const fweight = z.cre === '' ? 'font-weight-bold h6 m-0' : '';
                                return (
                                    <tr key={uid()} className={`${clss} ${border}`}>
                                        <td className=" mxw-50 border-0 p-2 small align-middle position-relative">
                                            <div className="d-flex w-100 align-items-center">
                                                <div className={`zonas-legend status-${z.estado}`}></div>
                                                <div className={`w-100 d-block pl-4 ${fweight}`}>{z.nome}</div>
                                            </div>
                                        </td>
                                        <FirstRow zone={z}></FirstRow>
                                    </tr>
                                )
                            }) 
                        }
                        {!postosData[0] && (
                            <tr><td colSpan="2" className="small">Nenhuma zona encontrada...</td></tr>
                        )}
                    </tbody> 
                </Table>
                        
                {
                    /**
                     * 
                     * <Accordion className="mt-3">
                    {
                        postosData.map((z,i) => {
                            const clss = z.cre === '' ? 'bg-light ' : '';
                            const border = z.cre === '' ? 'border-bottom' : '';
                            const fweight = z.cre === '' ? 'font-weight-bold h6 m-0' : '';
                            const highlights = z.votos?.filter( x=>x.highlight === 1 ) || [];
                            const winner = highlights.length === 1 ? highlights[0] : null;
                            return (
                                <Card className="border-0 shadow mb-3 rounded" key={i}>
                       
                                    <Accordion.Toggle className="d-flex border-0 py-2 px-3 bg-white text-left align-items-center" style={{minHeight:55}} eventKey={i+1}>
                                        
                                        <div className="d-flex align-items-center" style={{width:'60%'}}>
                                            <div className={`zonas-legend status-${z.estado}`}></div>
                                            <div className={`w-100 d-block pl-4 ${fweight} small`}>{z.nome}</div>
                                        </div>

                                        <div style={{width:'40%'}}>
                                            <div>
                                                { winner && (
                                                    <div className="d-flex small text-capitalize align-items-center text-blue">
                                                        <img style={ {width:50} }src={ imgPath( getCandidato(winner.id).image ) } />
                                                        <div >
                                                            <span className="truncate-1 font-weight-600">{ getCandidato(winner.id)?.name?.toLowerCase()}</span>
                                                            <span className="font-weight-600">{winner.value}</span> votos
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            
                                        </div>

                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={i+1}>
                                        <Card.Body>
                                            <div className="row text-md-center">
                                            {
                                                z.votos.map( (votos,x)=>{
                                                    const candidato = getCandidato(votos.id);
                                                    return (
                                                        <div key={i+'-'+x} className=" col-12 col-md small d-flex d-md-block mb-3 zones-accordion-detail" >
                                                            <span className="text-capitalize truncate-2  mr-3 mr-md-0">{ candidato?.name?.toLowerCase() }</span>
                                                            <span className={ ` ${votos.highlight === 1? 'font-weight-600' : ''} ` }>{votos.value}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>

                                </Card>
                                
                            )
                        }) 
                    }
                    </Accordion>
                     */
                }
                   
                   
            </Modal.Body>
        </Modal>
            

        
        </>
    ) : null;
}

export default connect( (s)=>({...s}) )(PostosModal) 