
import  { FETCH_MESAS_GEO, FETCH_MESAS_REF, SHOW_MESAS_WITHOUT_RESULTS, SHOW_MESAS_WITH_RESULTS } from './action-type'

const initialState = {
    
    cre : {

        geojson : null,

        data: null,

        selected : null
    },

    postos : {
        geojson : null,

        data: null
    },

    mesas : {
        geojson : null,

        data: null
    },

    refMesas : {
        mesas : [],

        data: null
    },

    showMesasWithResults : true,

    showMesasWithOutResults : true,

};


export default (state = initialState, action ) => {

    if( action.type === FETCH_MESAS_GEO ){
        return {
            ...state,
            mesas : {
                ...state.mesas,
                geojson : action.data
            }
        }
    }

    if( action.type === FETCH_MESAS_REF ){

        return {
            ...state,
            refMesas : {
                ...state.refMesas,
                mesas : action.data,
                status: action.status
            }
        }
    }

    if(action.type === SHOW_MESAS_WITH_RESULTS){
   
        return {
           ...state,
           showMesasWithResults : action.status
        }
    }

    if(action.type === SHOW_MESAS_WITHOUT_RESULTS){
   
        return {
           ...state,
           showMesasWithOutResults : action.status
        }
    }

    if(action.type === 'SET_CRE_GEO'){
   
        return {
           ...state,
           cre : {

                ...state.cre,

                geojson : action.data,
        
               
            },
        }
    }

    if(action.type === 'CLEAR'){

        return {
            ...state,
            cre : {
                ...state.cre,
                selected : null
            }
        }

    }

    if(action.type === 'SELECT_CRE'){

        if(action.data){

            return {    
                ...state,
                cre : {
     
                     ...state.cre,
     
                     selected : action.data,
             
                    
                },
             }

        }else{
            return {    
                ...state,
                cre : {
                    ...state.cre,
                    //geojson : null,
            
                    data: null,
            
                    selected : null
                },
             }
        }

        
    }

    return state;

};