import React from 'react'
import { connect } from 'react-redux'
import { Breadcrumb } from 'react-bootstrap'
import { selectRegion } from 'store/regions/actions'
import { fetchData } from 'store/elections/actions'
import { HiddenMesas } from 'components/v2/Map'

import Centers from 'resources/gis/centers.json'

const homeCode = "global";

const Mapcrumb = ( { elections, regions, fetchData, selectRegion})=>{

    const isHome       = elections.selected === homeCode;
    const home         = regions.data.filter( r=>r.code === homeCode )[0];
    const selected     = regions.data.filter( r=>r.code === elections.selected )[0];
    const parent       = regions.data.filter( r=>r.code === selected?.parent_code )[0];
    const { version }  = elections.update;
    const homeAttrs    = {};
    const selectedCode = selected ? selected.code : '';

    const getRegionByID = (id)=>{
        if(id)
            return  regions.data.filter( r=>r.code?.toUpperCase() === id?.toUpperCase() )[0];
    }

    
    if(isHome)
        homeAttrs.active = true;
    else
        homeAttrs.onClick = ()=>{
            fetchData( home.code, version)
        }

    return (
        <>
        <div className="d-flex align-items-center d-xl-none" style={{marginLeft:22}}>
            
            {!parent && (
                <button style={{width:'auto'}} onClick={ ()=>{
                    fetchData( home.code, version)
                } } className={`btn btn-light btn-sm py-2 app-zonas-modal-btn d-flex align-items-center justify-content-center text-capitalize  ${selectedCode === 'global' ? 'active' : ''}`}>
                    <small><i className="fas fa-globe"></i> { !elections.regionName && (
                        'Global'
                    ) }</small>
                </button>
            )}
            
            {parent && (
                <button onClick={ ()=>{
                    fetchData( parent.code, version)
                } } className={`btn btn-light btn-sm py-2 app-zonas-modal-btn d-flex align-items-center justify-content-center text-capitalize ${selectedCode === 'nacional' ? 'active' : ''}`}>
                    <small className="text-truncate d-block">{parent.name?.toLowerCase()}</small>
                </button> 
            )}

            {/*
            <button onClick={ ()=>{
                fetchData( 'nacional', version)
            } } className={`btn btn-light btn-sm py-2 app-zonas-modal-btn d-flex align-items-center justify-content-center text-capitalize ${selectedCode === 'nacional' ? 'active' : ''}`}>
                <small className="text-truncate d-block">Nacional</small>
            </button>
            
            <button onClick={ ()=>{
                fetchData( 'diaspora', version)
            } } className={`btn btn-light btn-sm py-2 app-zonas-modal-btn d-flex align-items-center justify-content-center text-capitalize ${selectedCode === 'diaspora' ? 'active' : ''}`}>
                <small className="text-truncate d-block">Diáspora</small>
            </button>

        */ }
            
            {elections.regionName && (
                <button onClick={ ()=>{
                    fetchData( selectedCode, version)
                } } className={`btn btn-light btn-sm py-2 app-zonas-modal-btn d-flex align-items-center justify-content-center text-capitalize ${selectedCode === 'diaspora' ? 'active' : ''}`}>
                    <small className="text-truncate d-block">{selected?.name.toLowerCase()}</small>
                </button>
            )}

         
        </div>
        <Breadcrumb className="app-mapcrumb px-4 py-1 shadow  d-none d-xl-flex" style={{zIndex:1000, borderRadius:10, padding:5}}>
            {home && (
                <Breadcrumb.Item className="small text-capitalize" {...homeAttrs} >{home.name.toLowerCase()}</Breadcrumb.Item>
            )}
            { !isHome && (
                <>
                {parent && (
                    <Breadcrumb.Item onClick={()=>{
                        fetchData(  parent.code, version);
                    }} active className="text-capitalize small cursor-pointer">
                        {parent.name.toLowerCase()}
                    </Breadcrumb.Item>
                )}
                {selected && (
                    <Breadcrumb.Item onClick={()=>{
                        fetchData(  elections.selected, version);
                    }} active className="text-capitalize small cursor-pointer">{selected.name.toLowerCase()}</Breadcrumb.Item>
                )}
                {elections.regionName && (
                    <Breadcrumb.Item active className="text-capitalize small">
                        {elections.regionName.toLowerCase()}
                    </Breadcrumb.Item>
                )}
                </>
            )}
        </Breadcrumb>
        </>
    )
}

const mapState = (s)=>({...s})

const mapDispatchToProps = (dispatch)=> ({
  
    fetchData : (code, version) => dispatch( fetchData(code, version) ),
    selectRegion : (r)=>dispatch( selectRegion(r) )
  
  });
  
export default connect(mapState, mapDispatchToProps)(Mapcrumb)
