import { useEffect } from 'react';
import { useMap } from 'react-leaflet'
import { connect } from 'react-redux'
import Centers from 'resources/gis/centers.json'
import { HiddenMesas } from '.'


const MapNavigation = connect((s)=>({...s}))( (props)=> {

    const map      = useMap();
    const selected = props.elections.selected;
    const showCre  = props.elections.showCre;
    const hasCre   = Centers[selected]?.cre === true;
    let center     = Centers[selected] ? Centers[selected].center : Centers.global.center;
    let zoom       =  Centers[selected] ? Centers[selected].zoom : Centers.global.zoom;

    //console.log(props.gis.cre);

    useEffect( ()=>{

      if( HiddenMesas[selected] ){
        
        map.flyTo(center, zoom, {
          animate:false,
        });

      }else{
          //if(!props.gis.cre.selected)

          for(const x in map._layers){
            const layer  = map._layers[x];
            if(layer.feature?.properties?.code === selected){
              map.fitBounds(layer.getBounds(), {
                animate:false
              });
              return false;
            }
          }
      }
    },[selected, props.elections.data.zones ] )

    useEffect( ()=>{
      if(props.gis.cre.selected)
        for(const x in map._layers){
          const layer  = map._layers[x];
          if(layer.feature?.properties?.code?.toLowerCase() === props.gis.cre.selected?.toLowerCase()){
            map.fitBounds(layer.getBounds(), {
              animate:false
            });
            return false;
          }
        }
    },[props.gis.cre.selected ] )
    
    if( !HiddenMesas[selected] && showCre && hasCre){
     // map.scrollWheelZoom.enable();
      //map.zoomControl.enable();
    }else{
     // map.scrollWheelZoom.disable();
      //map.zoomControl.disable();
    }

   // map.scrollWheelZoom.disable();

   // map.zoomControl.disable();

    return null;
  });

export default MapNavigation;