import React from 'react'
import DashboardView from 'components/Dashboard'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import Sidebar from 'components/v2/Sidebar'
import Header from 'components/v2/Header'
import Ranking from 'components/v2/Ranking'
import Stats from 'components/v2/Stats'
import Map from 'components/v2/Map'
import Footer from 'components/Theme/Footer'

const Dashboard = ( )=>{
    return( 
        <>
        <Header></Header>
        <Ranking/>
        <Row>
            <Col md="7"  xl="8">
                <Map></Map>
            </Col>
            <Col md="5"  xl="4">
                <Stats></Stats>
            </Col>
        </Row>
        <Footer></Footer>
        </>
    )
}

const mapState = (s)=>({...s})

export default connect(mapState)(Dashboard);

