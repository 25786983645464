export default {


    "baseUrl" : "/",
    "dashURL" : "resultados",
    "mapURL"  : "mapa",
    "compararURL"  : "comparar",

    "routes" : {
        "base"    : "/",
        "results" : "/resultados",
        "map"     : "/mapa"
    },

    "versionRequestTimeout" : 5000,
    
    "dates" : {
        "onRouteEnterValidation" : true,
        "electionDateTimestamp" : 1634500800000,
        "showElection" : {
            "year"    : 2021,
            "month"   : 9,
            "day"     : 17,
            "hours"   : 19, 
            "minutes" : 0,
            "seconds" : 0,
            "millis"  : 0
        },
        "showUrnsEff" : {
            "year"    : 2021,
            "month"   : 9,
            "day"     : 10,
            "hours"   : 15,
            "minutes" : 0,
            "seconds" : 0,
            "millis"  : 0
        }
    },
    "local_de_voto" : {
        "show" : true,
        "url"  : "https://api-pdex.gov.cv:8242/t/eleicoes.gov/publicLocalVote/1.0.0/_postpubliclocalvote",
        "token": "Bearer 2f1ad43f-1374-3604-9a41-270434defcf7"
    },

    "pesquisa_recenseado": {
        "show": true,
        "url"  : "https://api-pdex.gov.cv:8242/t/eleicoes.gov/pesquisaEleitores/1.0/_getquerypesquisaeleitor",
        "token": "Bearer 8a22fc4d-40e2-3bed-8764-9eeaee8e2629"
    },

    "gis" : {
        "dataUrl" : "/map/mesas.json",
        //"dataUrl" : "https://gis-teste.nosi.cv/geoserver/eleicao/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=eleicao%3AMesa%20Georreferenciado&maxFeatures=5000&outputFormat=application%2Fjson",
        "basemap" : {
            "url" : "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "attribution" : "&amp;copy <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
        }
    },


    "cvTimeUrl"  : "https://script.googleusercontent.com/macros/echo?user_content_key=TqcbPV3fOu_r7Wz5EOZR9kOK5Zuft9XR8su1Ur08XRQpvWTuY1j7rJkZtuuML0oUcaRc7s2DKKpR-VY4whipkP-IJFV5NZJWm5_BxDlH2jW0nuo2oDemN9CCS2h10ox_1xSncGQajx_ryfhECjZEnJ9GRkcRevgjTvo8Dc32iw_BLJPcPfRdVKhJT5HNzQuXEeN3QFwl2n0M6ZmO-h7C6bwVq0tbM60-56s-xPRFShKrLcZF-iq4I11ZGgGPA0h4&lib=MwxUjRcLr2qLlnVOLh12wSNkqcO1Ikdrk",

    "site" : {
        "name" : "Autárquicas 2020",
        "logo" : ""
    },
 
    "defaults" : {
        "context" : {
            "results" : {
                "label" : "Resultados"
            },
           /* "camara" : {
                "label" : "Câmara"
            },
            "assembleia" : {
                "label" : "Assembleia"
            }*/
        },
        "regions" : {
            "selected" : "nacional"
        }
    },

    "igrp" : {
        "env" : ""
    }
    
}