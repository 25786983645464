import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default ()=>{
    return (
        <Container fluid >
            <Row>
                <Col  className="left-countdown text-white d-flex align-items-center justify-content-center" style={ { minHeight:'100vh' } }>
                   <div className="text-center">
                        <img alt="logo" src="/images/logo_w.png"></img>
                       
                        <h5 className="mt-4">Página não encontrada...</h5>

                        <div className="mt-4">
                            <Link to="/" className="btn btn-outline-light">
                                <i className="fas fa-chevron-left small"></i> Voltar à página principal
                            </Link>
                        </div>
                   </div>
                </Col>
              
            </Row>
        </Container>
    )
}