import { GET_CADIDATES, SET_CANDIDATES } from './action-type';
import axios from 'axios'

export const setCandidatos = data => ({
    type: SET_CANDIDATES, 
    data
});

export const getCandidatos = () => async dispatch => {
    
    try{
        const res = await axios.get('/data/candidatos.json')
        
        dispatch( {
            type: GET_CADIDATES,
            data: res.data
        })
    }
    catch(e){
        console.log(e)
    }

}