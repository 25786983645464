import './Map.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import MapView from './MapView';
import CreController from './CreController'
import { Mapcrumb } from 'components/Charts';
import PostosModal from './PostosModal';

const Map = (props)=>{
    return (
        <div id="map" className="mt-4 ">
            <div className="d-flex align-items-center position-absolute w-100" style={{zIndex:1000,marginTop:20}}>
                <Mapcrumb/>
                <div style={{marginLeft:'auto', marginRight:22}} className="">
                    <PostosModal></PostosModal>
                </div>
            </div>
            <MapView/>
        </div>
    )
}


export const HiddenMesas = {
    global : true,
    nacional : true,
    diaspora : true
};

export default connect( (s)=>({...s}) )(Map) 