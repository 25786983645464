
import  { SET_MODE, SWITCH_MODE } from './action-type'

const initialState = {
    mode : 'dark',
    sidebar : false
};

export default (state = initialState, action) => {

    if(action.type === SET_MODE)

        return {
            ...state,
           mode : action.data
        }

    if(action.type === SWITCH_MODE)
        return {
            ...state,
           mode : state.mode === 'dark' ? 'light' : 'dark'
        }

    if(action.type === 'TOGGLE_SIDE')
        return {
            ...state,
            sidebar : !state.sidebar
        }

    if(action.type === 'HIDE_SIDE')
        return {
            ...state,
            sidebar : false
        }


 
        

  return state;

};