

export const Adapter=(original)=>{
    const data = {

        graphics : {

            [original.code] : {
                
                mesas : {

                    total : original.total_mesas,

                    pct   : original.pct_mesas_apuradas,

                    value : original.mesas_apuradas

                },

                votantes : {

                    total : original.votantes_total,

                    pct   : original.votantes_pct,

                    value : original.votantes_value,

                    abstention: original.abstencao_value,
                    
                    abstention_pct: original.abstencao_pct,

                    total: original.total_inscritos,

                    total_circulo: original.total_inscritos,

                   // pct_pub: 100

                } ,
                
                nulos : {

                    total : original.nulos_total,

                    pct   : original.nulos_pct,

                    value : original.nulos_value

                } ,

                brancos : {

                    total : original.brancos_total,

                    pct   : original.brancos_pct,

                    value : original.brancos_value

                },

                votos : original.votes

            }
        },

    };

    return data;
}