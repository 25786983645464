import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default ()=>{
    return (
        <Container fluid >
            <Row>
                <Col  className="left-countdown text-white d-flex align-items-center justify-content-center" style={ { minHeight:'100vh' } }>
                   <div className="text-center">
                        <img alt="logo" src="/images/logo_w.png"></img>
                        <h5 className="mt-4">Site Em Manutenção</h5>
                   </div>
                </Col>
              
            </Row>
        </Container>
    )
}