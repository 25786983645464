import React, { useState } from 'react'
import { Container, Form, FormControl, Button, Row, Col, Card,Alert } from 'react-bootstrap'
import axios from 'axios'
import {NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'
import { trackPromise } from 'react-promise-tracker'
import MaskInput from 'react-maskinput';
import 'moment/locale/pt'
import moment from 'moment-timezone'

const LocalDeVoto = (props)=>{

    const [ state, setState ] = useState({
        nome : "",
        data_nascimento : ""
    });

    const showHelp = new URLSearchParams(props.location.search).get("help") === "false" ? false : true;

    const [ rows, setRows ] = useState({});

    const [ loading, setLoading ] = useState(false);

    const [ validation, setValidation ] = useState({
        valid : false,
        errors : []
    });

    const handleInput = (e,i)=>{
        setState({
            ...state,
            [e.target.name] : e.target.value
        })
    }

    const send = ()=>{
        const body = {
            _postpubliclocalvote: state
        };

        setValidation({
            valid : true
        })

        if( state.data_nascimento && state.nome ){

            setRows([]);

            const xdate = state.data_nascimento.replace(/(\d*)-(\d*)-(\d*)/,'$3-$2-$1');

            if(state.nome.length <= 150){

                if(!moment('1900-01-01').isAfter(xdate)){
                    setLoading(true);

                    trackPromise(
                        
                        axios.post('_postpubliclocalvote',body).then( (e)=>{
                    
                            try{
                                
                                const result =  e.data.result;

                                if(!result ){
            
                                    setValidation({
                                        valid : false,
                                        errors : [
                                            { 
                                                type : 'info',
                                                message: "Não encontramos nenhum resultado. Redefina sua consulta"
                                            }
                                        ]
                                    })
                                }else{
            
                                    setRows(result.person);

                                    setValidation({
                                        valid : true
                                    });
            
                                }
                                
            
                            }catch(err){
                                console.log(err)
                                setValidation({
                                    valid : false,
                                    errors : [
                                        { 
                                            type : 'warning',
                                            message: "Não foi possivel realizar a consulta"
                                        }
                                    ]
                                })
                            }
                        }).then( ()=>{
                            setLoading(false);
                        }).catch( (e)=>{
                            setLoading(false);
                            setValidation({
                                valid : false,
                                errors : [
                                    { 
                                        type : 'warning',
                                        message: "Não foi possivel realizar a consulta"
                                    }
                                ]
                            })

                        } )
                    )
                }else{
                    setValidation({
                        valid : false,
                        errors : [
                            { 
                                type : 'danger',
                                message: "Não foi possivel realizar a consulta. Data de nascimento não pode ser inferior a 1900."
                            }
                        ]
                    })
                }
            }else{
                setValidation({
                    valid : false,
                    errors : [
                        { 
                            type : 'danger',
                            message: "Não foi possivel realizar a consulta. Nome demasiado longo."
                        }
                    ]
                })
            }
        }else{

            setValidation({
                valid : false,
                errors : [
                    { 
                        type : 'danger',
                        message: "Preencha os dois campos para realizar a consulta."
                    }
                ]
            })

        }   
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        send();
        return false;
    }

    const style = {
       formControl : {
            borderRadius : 8   
       }
    };

    const icon = loading ? 'fa-spinner fa-spin' : 'fa-search';

    const maxDate = new Date('04/18/2021');
    maxDate.setDate( maxDate.getDate() - 1 );
    maxDate.setFullYear( maxDate.getFullYear() - 18 );

    return (
        <>
            <Container fluid id="app-theme" className={`pb-3 px-0 light`} style={ { minHeight:300 , paddingTop:'150px', backgroundPosition:'fixed'} }>
                <Container className="position-relative app-search-view">
                    <Row className=" w-100 left-0" >
                        <Col className="mb-3">
                            <Card className="shadow border-0" >
                                
                                <Card.Body >
                                    
                                    <Row>
                                        <Col md="5">
                                            <NavLink to="/" className="small text-uppercase mb-2 d-block text-blue" style={ {fontSize:10} }><i className="fas fa-chevron-left "></i> Voltar</NavLink>
                                                <h6 className="text-uppercase text-blue" style={{lineHeight:'25px'}}>
                                                    
                                                    <span>Consulte o seu local de voto.</span>
                                                </h6>
                                                <p className="mt-3 mb-4 small" style={{color: 'rgb(161, 161, 161)'}}>Para uma pesquisa mais refinada, preencha os dois campos.</p>
                                                <Form onSubmit={ handleSubmit }>
                                                    <Form.Group>
                                                        <Form.Label className="small font-label"><b>Nome Completo</b></Form.Label>
                                                        <FormControl name="nome" type="text" onChange={handleInput} maxLength="250" value={state.nome} style={style.formControl}></FormControl>
                                                    </Form.Group>
                                                    <Form.Group>
                                                        <Form.Label className="small font-label"><b>Data de Nascimento</b></Form.Label>

                                                        <MaskInput 
                                                            placeholder="DD-MM-AAAA" 
                                                            className="form-control" 
                                                            name="data_nascimento" 
                                                            //alwaysShowMask
                                                            //maskChar="_" 
                                                            mask="00-00-0000" 
                                                            size={10} 
                                                            style={style.formControl} 
                                                            onChange={handleInput} value={state.data_nascimento}/>
                                                        
                                                    </Form.Group>
                                                    
                                                    <Button type="submit" block className="mt-5 app-local-de-voto-btn mb-4">
                                                        
                                                        <i className={`fas ${icon}`}></i> Consultar 
                                                    </Button>

                                                    

                                                </Form>
                                            </Col>
                                        <Col>
                                            {
                                                !validation.valid && (
                                                    validation.errors.map( (err,i)=>(
                                                        <Alert key={i} variant={err.type}>
                                                            {err.message}
                                                        </Alert>
                                                    ) )
                                                )
                                            }
                                            <div className="py-3 px-md-3">
                                            {rows.nome && (
                                                <>
                                                    <div className="app-local-result-item small">
                                                        <b>Nome</b>
                                                        <div>{rows.nome}</div>
                                                    </div>  
                                                    <div className="app-local-result-item small">
                                                        <b>Nome Mãe</b>
                                                        <div>{rows.nome_mae}</div>
                                                    </div> 
                                                    <div className="app-local-result-item small">
                                                        <b>Nome Pai</b>
                                                        <div>{rows.nome_pai}</div>
                                                    </div> 
                                                    <div className="app-local-result-item small">
                                                        <b>Concelho</b>
                                                        <div>{rows.concelho}</div>
                                                    </div> 
                                                    <div className="row m-0">
                                                        <div className="app-local-result-item small col-md">
                                                            <b>Dt. de Nascimento</b>
                                                            <div>{rows.data_nascimento}</div>
                                                        </div> 
                                                        <div className="app-local-result-item small col-md">
                                                            <b>Identificação</b>
                                                            <div>{rows.identificacao}</div>
                                                        </div> 
                                                    </div>
                                                    {
                                                        rows.posto && (
                                                        <div className="bg-light">
                                                            <div className="app-local-result-item small w-50">
                                                                <b>Posto</b>
                                                                <div>{rows.posto}</div>
                                                            </div> 
                                                        </div>
                                                        )
                                                    }
                                                    
                                                    <div className="bg-light py-2">
                                                        <div className="app-local-result-item small ">
                                                            <b>Local de voto</b>
                                                            <div>{rows.local_voto}</div>
                                                        </div> 
                                                        <div className="app-local-result-item small">
                                                            <b>Mesa</b>
                                                            <div>{rows.mesa_voto}</div>
                                                        </div>
                                                    </div>

                                                </>
                                                
                                            )}
                                            
                                    
                                            </div>

                                        </Col>
                                    </Row>
                                    
                                    {
                                        showHelp && (
                                            <Row>
                                                <Col>
                                                    <div className="">
                                                        Linha de apoio grátis <br/>
                                                        <a href="tel:8001014" className="h5 text-blue d-inline-block d-md-block d-lg-inline-block">800 10 14</a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default withRouter(LocalDeVoto);