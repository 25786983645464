import './Map.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import { GeoJSON, useMap } from 'react-leaflet'
import basemap from 'resources/gis/basemap.json'
import { fetchData } from 'store/elections/actions';
import { HiddenMesas } from '.';
import Centers from 'resources/gis/centers.json'

const Basemap = (props)=>{
    const map    = useMap();
    const series = props.elections.series;
    const selected = props.elections.selected;
    const style = {
        fillOpacity: .25,
        color:'rgba(0,0,0,.3)',
        weight : .6,
        fillColor : 'transparent'
    };
    return (
        <GeoJSON 
            id={'app-basemap'}
            data={basemap}
            onEachFeature={(feature,layer)=>{

                layer.bindTooltip(
                    '<span class="text-capitalize">'+feature.properties.name?.toLowerCase()+'</span>',{
                   // direction:'center',
                    className: '',
                    interactive : true,
            
                    sticky:true
                    
                });  

            }}
            style={(feature)=>{
                const code    = feature.properties ? feature.properties.code : false;
                const visible = HiddenMesas[selected] || selected === code ? ( selected == code && Centers[code]?.cre ? .5 : 1 ) : .1;
                style.fillColor = series[0] ? ( series.filter( s=>s.key === code )[0] ? series.filter( s=>s.key === code )[0].color : '' )  : '';
                style.fillOpacity = visible;

                if(props.elections.regionName)
                    style.fillOpacity = 0;

                return style;
            }}
            eventHandlers={{
                tooltipopen:(e)=>{
                    const layer   = e.layer;
                    const feature = layer.feature;
                    if( feature.properties.code == selected )  
                        e.tooltip._close()
   
                },
                click:(e)=>{
                    const code = e.layer?.feature?.properties?.code;
                    props.dispatch( fetchData(code) );  
                    //map.fitBounds( e.layer.getBounds() )
                    
                }
            }}
        />
    )
}

const mapStateToProps = (s)=>({...s});

export default connect( mapStateToProps )(Basemap) 