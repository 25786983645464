
import { connect } from 'react-redux'
import {GeoJSON } from 'react-leaflet'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { getRegionName } from 'utils'

const Postos = (props)=>{
    
    const {version} = props.elections.update;
    
    const { code } = props.elections.region;

    const [postoData, setPostoData] = useState(null);
    
    

    useEffect( ()=>{
        if(version){
            axios.get( '/data/'+version+'/posto/'+code+'.json').then( (d)=>{
                //setPostoData(d.data);
            } )
        }
      
    },[] )

    return (
        <>
        {
            props.data  && (
                <GeoJSON 
                    data={props.data}
                    onEachFeature={(feature,layer)=>{
                        const _data      = postoData?.filter( d=>d.code?.toUpperCase() === feature.properties.code?.toUpperCase() )[0];
             
                        if(_data && feature.properties.name)
                            layer.bindTooltip(
                                '<div class="text-capitalize">'+feature.properties.name?.toLowerCase()+'</div>',{
                                //getRegionName(feature.properties.code, feature.properties.name) , {
                                //permanent:true,
                                direction:'center',
                                className: 'countryLabel',
                                interactive : true
                            });  
                    }}
                    style={(f)=>{
                        
                        const _data      = postoData?.filter( d=>d.code?.toUpperCase() === f.properties.code?.toUpperCase() )[0];
                        
                        let style        = {
                            fillOpacity: _data ? 1 : 1,
                            color:'black',
                            weight : .1,
                            fillColor : _data ? _data.color : 'transparent',
                           
                        };

                        return style;
                        
                    }}
                    eventHandlers={{
                        click:(e)=>{
                            console.log(e)
                        }
                    }}
                ></GeoJSON>
            )
        }
        </>
    )
}

export default connect( (s)=>({...s}) )(Postos) 