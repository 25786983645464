import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import {removeSession} from 'utils';
import Config from 'config'

export default function configureStore(initialState) {

    removeSession(Config.sessionVar);

    const enhancer = applyMiddleware(thunk)

    return createStore(
        rootReducer,
        initialState,
        enhancer
    );
}
