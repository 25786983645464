import './Ranking.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import { format, imgPath } from 'utils'
import { Card } from 'react-bootstrap';
import flagCorner from 'images/flag.corner.png'
import Skeleton from 'react-loading-skeleton';

const RankingVertical = (props)=>{
    const { votos } = props.elections.charts;
    const getCandidato = (id) => {
        return props.candidatos.filter( item=>( item.id.toLowerCase() === id.toLowerCase() ) )[0] || {};
    }
   
    return (
        <>
            <div id="votes-table" className="pb-5 text-blue">     
                <h5 className="mb-4" style={{ fontSize:16, fontWeight:600 }}>Ranking</h5>
                {votos.map( (v,i)=>{        
                    const candidato = getCandidato(v.id) || {};
                    return  (       
                        <div key={v.id+'-'+i} className="d-sm-flex mb-5 mb-sm-4 align-items-center text-blue">
                            <div className="d-flex align-items-center col" >
                                <div style={{ 
                                    backgroundImage:`url( /images/candidatos/${v.id?.toLowerCase()}.jpg )` , 
                                    minWidth:45, 
                                    height:55, 
                                    backgroundSize:'cover',
                                    backgroundPosition:'top'
                                }}/>
                                <div className="ml-3 text-blue w-100 vote-item-name" style={{minWidth:140, maxWidth:140}}>
                                    <span className="text-capitalize truncate-2 font-weight-500" style={{fontSize:15}}>{candidato.name?.toLowerCase()}</span>
                                </div>
                            </div>
                            <div className="ml-sm-3 mt-2 mt-sm-0 col d-flex">
                                <div className="d-flex align-items-center ml-md-auto box-info-wrapper">
                                    <b>{v.pct}%</b>
                                    <div className="box-bar d-flex ml-3" style={{width:90}}>
                                        <div className="box-bar-color shadow" style={{backgroundColor:candidato.color}}></div>
                                        <div className="box-bar-wrapper">
                                            <div className="box-bar-pct" style={{backgroundColor:candidato.color, width:`${v.pct}%`}}></div>
                                        </div>
                                    </div>
                                    <div><span className="font-weight-500">{ v.votes }</span> <small>votos</small></div>
                                </div>
                                
                            </div>
                        </div>
                    )
                    })}
                </div>
        </>
        
    )
}

const mapStateToProps = (s)=>({...s});


export default connect( mapStateToProps )(RankingVertical) 