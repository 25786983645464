import Footer from 'components/Theme/Footer';
import Header from 'components/v2/Header';
import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';

import Map from './App/App';

export default ()=>{
    return (
        <>
        <Header title={ 'Detalhe de Mesas' }></Header>
        
        <Container fluid className="position-relative p-0 mt-2">
            <Row>
                <Col>
                    <NavLink to="/resultados" className="text-blue mb-2 d-block small ml-3">
                        <i className="fas fa-chevron-left"></i> voltar
                    </NavLink>
                </Col>
            </Row>
            <Row className=" w-100 left-0 mx-0" noGutters>
                <Col className="mb-3">
                    <Card className="shadow border-0">
                        <Card.Body className="p-0">
                            <Map/>
                        </Card.Body>
                    </Card>
                   
                </Col>
            </Row>
        </Container>
        </>
    )
}