import React, { Suspense } from 'react'
import Routes from './routes'
import Loading from 'components/Loading'
import configureStore from 'store';
import Config from 'config'
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom"
import { createBrowserHistory } from 'history';
import { ToastProvider } from 'react-toast-notifications'

import ScrollToTop from 'components/ScrollTop'

import Names from 'resources/gis/names.json'

import moment from 'moment';

import 'moment-timezone'

const browserHistory = createBrowserHistory();
const store = configureStore();

moment.tz.setDefault("Atlantic/Cape_Verde");

const App = () => {

  return (
    <Provider store={ store }>
      <ToastProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <Loading></Loading>   
          <Router basename={ Config.baseUrl } history={browserHistory}>
            <ScrollToTop />
            <Routes></Routes>
          </Router>
        </Suspense>
      </ToastProvider>
    </Provider>
  )
}

export default App;//connect( mapStateToProps )( App )