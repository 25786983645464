
import  { SET_CANDIDATES, GET_CADIDATES } from './action-type'

const initialState = []

export default (state = initialState, action) => {
    
    if(action.type === SET_CANDIDATES){

        return  [...action.data]
           
    }

    if(action.type === GET_CADIDATES){

      return [...action.data]

    }


  return state;

};