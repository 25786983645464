import './Header.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import Logo from 'images/logo.png'
import bgBlue from 'images/bg.blue.png'
import flagTop from 'images/flag.top.png'
import { toggleSide } from 'store/theme/actions'

import moment from 'moment'

import 'moment-timezone';
import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { NavLink } from 'react-router-dom';

const Header = (props)=>{
    const _data               = props.elections.update.date || "";
    const _time               = props.elections.update.time;
    const selectedCode        = props.elections.selected;
    const selectedRegion      = props.regions.data[0] ? props.regions.data.filter( item=>( item.code === selectedCode ) )[0] : {};
    const [ fixed, setFixed ] = useState(false);

    const isTabletOrMobile    = ()=> window.innerWidth <= 992;

    const toggleFixed = (e)=>{
        if(isTabletOrMobile()){
            e.preventDefault();
            setFixed(window.scrollY > document.querySelector('#header').getBoundingClientRect().height + 60 )
        }else{
            setFixed( false )
        }
    }

    useEffect(()=>{
        window.addEventListener('scroll', toggleFixed);
        return ()=> window.removeEventListener('scroll', toggleFixed);
    },[]);

    return (
        <>
            <div className="d-flex d-lg-none align-items-end mb-3">
                <NavLink to="/">
                    <img src={Logo} style={{ width:80 }}/>
                </NavLink>
                <div className="text-blue ml-3" style={{fontSize:14}}>
                        {_('PROV_RESULTS')}
                        <div className="d-flex ">
                            <span className="" style={{fontWeight:200}}>{_('UPDATED_AT')}</span>
                            {/*<span className="ml-1">{_data}, {_time} </span>*/}
                            <span className="ml-1">
                                <Moment fromNow>{props.elections.update.timestamp}</Moment>
                                {/*moment( props.elections.update.timestamp ).fromNow()*/}</span>
                            
                            <a href="#" target="blank" className="text-reset text-decoration-none ml-2 d-none">
                                <i className="far fa-question-circle"></i>
                            </a>
                        </div>
                </div>
            </div>
            <div id="header" style={{ backgroundImage: `url(${bgBlue})`  }} className={`p-2 px-lg-4 py-lg-3 o overflow-hidden ${ fixed ? 'fixed' : '' }`}>
                <img className="bg-blue d-none d-lg-block" src={flagTop}/>
                <div className="d-flex w-100 text-white position-relative align-items-center">
                    <div className="d-block d-lg-none px-3  cursor-pointer" onClick={()=>{ props.dispatch( toggleSide() ) }}>
                        <i className="fas fa-bars"></i>
                    </div>
                    <div className="region-name cursor-pointer" style={{fontWeight:600, fontSize:16}} onClick={()=>{ props.dispatch( toggleSide() ) }}>
                        {
                            props.title ? (
                                props.title
                            ):(
                                <>
                                { selectedRegion.name }
                                {
                                    props.elections.regionName && (
                                        <div style={{lineHeight:'10px'}}><small className="text-capitalize">{props.elections.regionName?.toLowerCase()}</small></div>
                                    )
                                }
                                </>
                            )
                        }
                        
                        
                    </div>
                    <div className="d-none d-lg-flex flex-column small ml-4" style={{fontWeight:600}}> 
                        {_('PROV_RESULTS')}
                        <div className="d-flex">
                            <span style={{fontWeight:200}}>{_('UPDATED_AT')}</span>
                            {/*<span className="ml-1">{_data}, {_time} </span>*/}
                            <span className="ml-1">
                                <Moment fromNow >{props.elections.update.timestamp}</Moment>
                            </span>
                            <a href="#" target="blank" className="text-reset text-decoration-none ml-2 d-none">
                                <i className="far fa-question-circle"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (s)=>({...s});

export default connect( mapStateToProps )(Header) 