import './VotesTable.css';
import { connect } from 'react-redux'
import _ from 'components/Translate'
import { imgPath } from 'utils';

const VotesTable = (props)=>{
    const regionData   = props.elections.electedModalData?.data;
    const getCandidato = (id)=>{
        return props.candidatos[0] ? props.candidatos.filter( c=>(c.id.toUpperCase())===id.toUpperCase() )[0] : {};
    }
    return (
        <div id="votes-table" >
           {regionData?.votes.map( (v,i)=>{
                const candidato = getCandidato(v.id);
                return(
                    <div key={v.id+'-'+i} className="d-sm-flex mb-5 mb-sm-4 align-items-center text-blue">
                        <div className="d-flex align-items-center" >
                            <div style={{ 
                                backgroundImage:`url(${imgPath(candidato.image)})` , 
                                minWidth:45, 
                                height:55, 
                                backgroundSize:'cover'
                            }}/>
                            <div className="ml-3 text-blue w-100 vote-item-name" style={{minWidth:140, maxWidth:140}}>
                                <span className="text-capitalize truncate-2 font-weight-500" style={{fontSize:15}}>{candidato.name?.toLowerCase()}</span>
                            </div>
                        </div>
                        <div className="ml-sm-3 mt-2 mt-sm-0">
                            <div className="d-flex align-items-center">
                                <b>{v.pct}%</b>
                                <div className="box-bar d-flex ml-3" style={{width:90}}>
                                    <div className="box-bar-color shadow" style={{backgroundColor:candidato.color}}></div>
                                    <div className="box-bar-wrapper">
                                        <div className="box-bar-pct" style={{backgroundColor:candidato.color, width:`${v.pct}%`}}></div>
                                    </div>
                                </div>
                                <div><span className="font-weight-500">{ v.votes }</span> <small>votos</small></div>
                            </div>
                            
                        </div>
                    </div>
                )
            })}
        </div>
    )
}


export default connect( (s)=>({...s}) )(VotesTable) 