import React from 'react';
import {  Row, Col } from 'react-bootstrap'
const Footer = ()=>{
    return (
        <div className="app-footer px-2 d-flex align-items-center mt-5">
            <div className="mb-2 text-center text-sm-left pr-4">
                <img alt="dgape" src="/images/logo-dgape.png" ></img>
            </div>
            <div className="mb-2 text-center text-md-left">
                <img alt="cne" src="/images/logo-cne.png" ></img>
            </div>
            <div className="text-right mb-2 w-100 position-relative">
                <span style={{color:'#bbbbbf'}} className="position-absolute poweredby d-block d-sm-inline-block text-muted mr-2 text-uppercase font-weight-500 small">Powered by</span>
                <img alt="nosi" className="img-poweredby d-inline-block" src="/images/logo-nosi.svg"></img>
            </div>
        </div>
    )
}

export default Footer;