import React from 'react'
import { connect } from 'react-redux'
import { format, imgPath } from 'utils'
import _ from '../Translate';

const Candidates = (props)=>{
   
    const { votos } = props.elections.charts;
    
    const getCandidato = (id) => {
        return props.candidatos.filter( item=>( item.id === id ) )[0] || {};
     }

    return(
        <div className="row flex-nowrap app-candidates align-items-center px-3" style={{ overflowX:'auto',overflowY:'hidden' }}>
            {votos.map( (c,i)=>{
                const candidato = getCandidato(c.id);
                return (
                    <div key={i} className={`col-5 col-sm-3 col-lg app-candidato-item d-flex flex-column flex-row text-center`}>
                        <div style={{ borderRadius:'100%', width: 65, minWidth:65, minHeight:65, margin:'auto',border:'2px solid white', padding:'3px' }} >
                            <img src={imgPath(candidato.image)} className="d-block" />
                        </div>
                        <span className="my-2 app-candidate-name text-light small text-uppercase">
                            {candidato.name}
                        </span>
                        <div className="text-light font-weight-bold mb-2">
                            <span>{c.pct}</span><b>%</b>
                        </div>
                        <div className="w-100 d-flex align-items-center" >
                            <div className="rounded-left" style={{ width: 10, height:15, backgroundColor:candidato.color }}></div>
                            <div className="border shadow w-100  rounded-right" style={{backgroundColor:'rgba(255,255,255,.1)'}}>
                                <div className="shadow" style={{ width: `${c.pct}%`, height:15, backgroundColor:candidato.color }}></div>
                            </div>
                        </div>
                        <div className="mt-2 text-light">
                            <small><b>{format(c.votes)}</b> votos</small> 
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default connect(s=>({...s}))(Candidates)