import React from 'react'
import { Component } from 'react'
import { connect } from 'react-redux';
import { fetchVersion, setUpdate } from'store/elections/actions'
import { getCandidatos } from 'store/candidatos/actions'
//import { getDeputados } from 'store/deputados/actions'
import { fetchData } from 'store/elections/actions'
import { useToasts } from 'react-toast-notifications'
import config from 'config';

class ThemeDataProvider extends Component {

    componentDidMount(){
      
        this.props.fetchVersion();

        let notFocus = this.props.hasOwnProperty('notFocus') && this.props.notFocus === true ? true : false;
        
        this.interval = setInterval( () => {

            if(document.hasFocus() || notFocus) //only if window is focused
                this.props.fetchVersion().then( d=>{
                    if(d.updated){
                        this.props.addToast(<div>{`Dados atualizados às `} <br></br><b>{d.time}</b></div>,{
                            appearance: 'success',
                            autoDismiss: true,
                        });
                    }
                })

        }, config.versionRequestTimeout );

    }

    componentWillUnmount(){

        clearInterval( this.interval );
    }

    render(){

        return (
            <>
                {this.props.children}
            </>
        ) 
    }
}

const mapStateToProps = (state) => ({...state})

const mapDispatchToProps = (dispatch)=>{
    
    dispatch( getCandidatos() );

    //dispatch( getDeputados() );

    return {

        setUpdate : (data)         => dispatch( setUpdate(data) ),

        fetchData : (code,version) => dispatch( fetchData(code,version) ),

        fetchVersion : ()          => dispatch( fetchVersion() )

    }

}

const withToast = (Component)=>{
    return function WrappedComponent(props) {
      const toastFuncs = useToasts()
      return <Component {...props} {...toastFuncs} />;
    }
  }
  

export default connect(mapStateToProps,mapDispatchToProps)(withToast(ThemeDataProvider))
