import React from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col} from 'react-bootstrap'
import {LeftComponent} from 'components/Countdown'

const CountDownPage = ( { countdown, tick, fetchTime, session } )=>{

    const { isAffluenceDay} = countdown;
    return (   
        <Container fluid className={session+' p-0'} afluence-urnes={isAffluenceDay ? 'true' : 'false'}>
            <Row>
                <Col /*md={!isAffluenceDay ? 12 : 6}*/ className="left-countdown text-white">
                    <LeftComponent session={session} />
                </Col>
            </Row>
        </Container>
    ) 
}

export default connect( s=>({...s}))(CountDownPage);