import React from 'react'
import { Card } from 'react-bootstrap'
import Votos from './Votos2'
import { connect } from 'react-redux'
import { showElectedModal } from  'store/elections/actions'
import TextTransition  from 'react-text-transition'

const VotosEleitos = ({elections, dispatch})=>{
    return (
        <div>
            <Card className="card-votos-eleitos" id="votos">
                <Card.Body>
                    <div className="d-flex ">
                        <div>
                            <b><TextTransition inline text="Votos" /></b>
                        </div>
                        <div className="ml-auto">
                            <div className="cursor-pointer" onClick={ ()=>{ dispatch( showElectedModal() ) } } style={{marginRight:20}}>
                                <b className="mr-2"><TextTransition inline text="Deputados" /></b> <i className="fas fa-ellipsis-v ml-1"></i>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Votos votos={elections.charts.votos} title=""></Votos>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default connect( (s)=>({...s}) )(VotosEleitos);