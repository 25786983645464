import React, { useEffect, useState} from "react";
import {capitalize} from 'utils' 
import Config from 'config'
import { Link } from "react-router-dom";
import { connect } from 'react-redux'
import { fetchTime, tick, setCurrentTime} from 'store/countdown/actions'

import LogoWhite from 'images/logo.md.png'

import 'moment/locale/pt'
import moment from 'moment-timezone'

import Clock from 'components/Countdown/Clock'
import axios from "axios";

moment.locale('pt');

window._isFirstDate = true;

const Countdown = ( { countdown, fetchTime, tick, session, dispatch } ) => {
    const { fetched, isElectionDay, isAffluenceDay, electionDate, currentDate, clock} = countdown;

    const [refresh, setRefresh] = useState(false);

    const goGetData = ()=>{

        const start = new Date().getTime();

        let version = null;
        let time    = null;

        let getTimeExt = location.hostname === "localhost" || location.hostname === "127.0.0.1" ? '.json' : '';
        console.log('ext: '+getTimeExt);

        const arr = [ 
            axios.get('/data/version.json?v='+(new Date().getTime())).then( (d)=>{
                version = d.data;
            } ), 
            axios.get('/getTime'+getTimeExt+'?v='+(new Date().getTime())).then( (d)=>{
                time = d.data;
            } )
        ];
        
        axios.all( arr ).finally( (v)=>{
           // const dif         = new Date().getTime() - start;
           
            const params = {    
                status   : version?.status_site,
                timezone :  time?.timestamp 
            };

            dispatch(setCurrentTime(params));
            
        } ).catch(errors => {
            console.log(errors);
        });/*.then(axios.spread((...responses) => {
            const version     = responses[0]?.data;
            const time        = responses[1]?.data;
            const dif         = new Date().getTime() - start;

            console.log
            
            const params = {    
                status   : version.status_site,
                timezone :  time.timestamp + dif
            };

            dispatch(setCurrentTime(params));


        }))*/
        
        /*axios.get('/getTime?v=_'+(new Date().getTime())).then( (d)=>{
            var dif = new Date().getTime() - start;
            
            setCurrentTime(d.data.timestamp +dif );
        } ).catch( ()=>{
            //var dif = new Date().getTime() - start;
      
            //setCurrentTime( new Date().getTime() +dif );
        })*/

    }

    const removeListener = ()=>{

       // window.removeEventListener('focus', fetchTime);
    }

    useEffect( ()=>{
        
        goGetData();

        return ()=> removeListener();

    }, [null] );

    useEffect( ()=>{
        let timeout = setTimeout( ()=>{
            goGetData();
            setRefresh(!refresh);
        },5000 );
        return ()=>clearTimeout(timeout);

    }, [refresh] );

    return  (
        <div className="countdown text-center text-white d-flex w-100 text-center justify-content-center align-items-center">                
            <div className="logo mb-2 mb-md-4">
                <img alt="Eleições Legislativas" src={LogoWhite}></img>
            </div>
            <div className="election-day p-3">
                <b>{Config.dates.showElection.day}</b> de <b>{ capitalize(moment().month(Config.dates.showElection.month).format("MMMM"))}</b> de <b>{Config.dates.showElection.year}</b>
            </div>
            {fetched && (
                <>
                    <div className="mb-0 py-2 px-4" style={{ lineHeight:'25px' }}>
                        <small>Acompanhe os <b>resultados provisórios</b> </small><div><small>a partir das</small>  <b className="m-0">{Config.dates.showElection.hours}</b> <small className="">horas de Cabo Verde</small></div>
                    </div>

      
                    <Clock></Clock>
                    
                    <div className="local-voto mt-3 mt-md-5">
                        <Link to="/local-de-voto" className="p-3 small" >
                            Meu <b> Local de Voto</b> <i className="fas fa-chevron-right small ml-2"></i>
                        </Link>
                    </div>

                    
                   {/* <div className="local-voto mt-3 mt-md-5">
                        {Config.pesquisa_recenseado.show && (!session || session === '') && (
                            <Link to="/local-de-voto" className="p-3 small" >
                                Meu <b> Local de Voto</b> <i className="fas fa-chevron-right small ml-2"></i>
                            </Link>

                        )}
                        Config.pesquisa_recenseado.show && (!session || session === '') && (
                            <Link to="/pesquisa-recenseado" className="p-3 small d-block" >
                                Consulte a tua inscrição <br></br> 
                                Nos cadernos de 
                                <b> Recenseamento</b> <i className="fas fa-chevron-right small ml-2"></i>
                            </Link>

                        )
                    </div>*/}
                </>
            )}
            {!fetched && (
                <div className="text-center">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>
            )}
            <div className="logo-footer mt-5 mb-3 position-absolute">
                <div className="d-none d-md-block text-center">
                    <img alt="dgape" src="/images/logo-dgape.png" className="mr-4 mr-md-5 d-inline-block"></img>
                    <img alt="cne" src="/images/logo-cne.png" className="d-inline-block"></img>
                    {!isAffluenceDay &&  
                        <div className="text-right d-inline-block ml-4 ml-md-5">
                            <span className="poweredby d-inline-block text-muted mr-2 text-uppercase font-weight-bold">Powered by</span>
                            <img alt="NOSi" className="img-poweredby d-inline-block" src="/images/logo-nosi.svg"></img>
                        </div> 
                    }
                </div>
            </div>
        </div>
    ) 
}

export default connect( (s)=>({...s}), dispatch=>({
    dispatch : dispatch,
    fetchTime: ()=>dispatch( fetchTime() ),
    tick : (start)=> dispatch( tick(start) )

}) )( Countdown );