import { SET_MODE, SWITCH_MODE } from './action-type';


export const setMode = data => ({
    type: SET_MODE, 
    data
});



export const switchMode = data => ({
    type: SWITCH_MODE, 
    data
});

export const toggleSide = data => ({
    type: 'TOGGLE_SIDE'
});


export const hideSide = data => ({
    type: 'HIDE_SIDE'
});

