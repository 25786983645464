import 'react-leaflet-markercluster/dist/styles.min.css'; 

import React from 'react';

import { MapContainer,TileLayer, useMapEvents } from 'react-leaflet'

import { Form, ListGroup } from 'react-bootstrap';
import MapClusters from './MapClusters';
import { SHOW_MESAS_WITHOUT_RESULTS, SHOW_MESAS_WITH_RESULTS } from 'store/gis/action-type';

window.DataFeatures = null;

const MapEvents = (props)=> {
    
    const map = useMapEvents({
      
    })
  
    return null
}

class MapView extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            data : this.props.data,
            center : this.props.center,
            zoom : this.props.zoom,
            searchList : [],
            withResults: SHOW_MESAS_WITH_RESULTS,
            withOutResults: SHOW_MESAS_WITHOUT_RESULTS
        }
    }

    clearSearch(){
        this.setState({
            searchList : []
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.center !== this.state.center || prevProps.zoom !== this.state.zoom){
            this.mapReference.flyTo( this.props.center, this.props.zoom, {
                animate: false
            } )

            this.setState({
                center: this.props.center,
                zoom:  this.props.zoom
            })

        }
    }

    goHome(){
        try{
            if(this.mapReference){
                this.mapReference.setView([16,-24], 2)
            }
        }catch(err){
            
        }
    }

    handleKey(e){

        let val = e.target.value;

        if(val && this.props.data && this.props.data.features  && this.props.data.features[0]){
            
            val = val.toUpperCase();
            const items = this.props.data.features.filter( (item)=>{
                const { properties } = item;
                const codMesaValid = properties.CODIGO_MES && properties.CODIGO_MES.toUpperCase().indexOf(val) >= 0;
                const concelhValid = properties.CONCELHO && properties.CONCELHO.toUpperCase().indexOf(val) >= 0;
                const descValid    = properties.DESCRICAO && properties.DESCRICAO.toUpperCase().indexOf(val) >= 0;
                const refMesaValid = properties.MES_REFERENCIA && properties.MES_REFERENCIA.toUpperCase().indexOf(val) >= 0;
                
                return codMesaValid || concelhValid || descValid || refMesaValid;
            });

            this.setState({
                searchList : items
            })

        }else{
            this.clearSearch();
        }
        
    }

    render(){

        const { showMesasWithResults, showMesasWithOutResults} = this.props.gis;
        
        return (
            <>
            <MapContainer 
         
                style={ { minHeight:'600px' } }
                className=" map-view" 
                center={ this.props.center } 
                zoom={ this.props.zoom } 
                zoomControl={true} 
                fullscreenControl={true}
                onClick={this.props.mapClick}
                whenReady={ (o)=>{
                    this.mapReference = o.target;
                    if(this.props.whenReady){
                        this.props.whenReady(o)
                    }
                } }

                >
                <TileLayer {...this.props.config.basemap}/>

                <MapClusters markerClick={this.props.markerClick}/>

                <MapEvents moveEnd={this.props.onMoveend}/>
                
            </MapContainer>
            <div className="position-absolute small app-map-searcher" style={ {  } }>
                
                <Form.Group className="position-relative mb-0">
                    <Form.Control onKeyUp={this.handleKey.bind(this)} placeholder="Pesquisar mesa por código ou nome" type="text" style={ {background:'rgba(255,255,255,.95)', border:0} }></Form.Control>
                    {this.state.searchList[0] && (
                        <div className="position-absolute p-2 cursor-pointer" onClick={this.clearSearch.bind(this)}  style={ { top:0,right:0 } }>
                            <i className="fas fa-times"></i>
                        </div>
                    )}
                </Form.Group>
                <div className="text-right p-1">
                    <i className="fas mr-1 fa-info-circle"></i>
                    <small style={{fontWeight:500, textShadow:'0 0 8px #b2b2b2'}}>
                        Algumas mesas não têm geolocalização exata
                    </small>
                </div>
                <div className="mt-0">
                    <ListGroup style={ { maxHeight: 420, overflow:'auto' } }>
                    {this.state.searchList[0] && (
                        this.state.searchList.map( (item, i)=>{
                            return (
                                <ListGroup.Item className="cursor-pointer" key={i} onClick={ ()=>{
                                    this.props.markerClick(item)
                                } }>
                                    <b>{item.properties.DESCRICAO}</b><br></br>
                                    <span>{item.properties.MES_REFERENCIA} - {item.properties.CONCELHO}</span>
                                </ListGroup.Item>
                            )
                        } )

                    )}
                    </ListGroup>
                </div>
            </div>
            <div className="position-absolute app-map-legend text-right rounded bg-light p-2 shadow" style={{zIndex:1000,right:15,bottom:30}}>
                <div onClick={() => this.props.showMesas(this.state.withResults)} className="cursor-pointer app-legend d-flex align-items-center w-100" style={{lineHeight:'24px'}}>
                    <input type="checkbox" checked={showMesasWithResults} onChange={ ()=>{} }/>
                    <span className={"text-uppercase mx-2  " + (showMesasWithResults ? 'text-black' : 'text-secondary')} style={{fontSize:10}}> Mesa apurada</span>
                    <i className="fas fa-map-marker-alt app-map-icon closed ml-auto" style={{fontSize:14}}></i> 
                </div>
                <div  onClick={() => this.props.showMesas(this.state.withOutResults)} className="cursor-pointer app-legend d-flex align-items-center w-100" style={{lineHeight:'24px'}}>
                    <input type="checkbox" checked={showMesasWithOutResults} onChange={ ()=>{} }/>
                    <span className={"text-uppercase mx-2  " + (showMesasWithOutResults ? 'text-black' : 'text-secondary')} style={{fontSize:10}}>Sem resultados</span>
                    <i className="fas fa-map-marker-alt app-map-icon ml-auto" style={{fontSize:14}}></i> 
                </div>
            </div>
            <div onClick={ this.goHome.bind(this) } className="position-absolute bg-white small text-center d-flex cursor-pointer app-map-home" style={{zIndex:1000,left:10,top:80, padding: 8, borderRadius:2}}>
                <i className="fas fa-globe"></i>
            </div>
            </>
        )
    }

}

export default MapView;