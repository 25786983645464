import React, { Component } from "react";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class PieChart extends Component {
  state = {
    chartData: {
      chart: {
        type: "pie",
        height: 120,
        marginBottom: 0
      },
      title: {
        text: ''
      },
      subtitle: false,
      series: [
        {
          data: this.props.data
        }
      ],
      tooltip: {
        pointFormat: "<b>{point.y}</b>"
      },
      plotOptions: {
        pie: {
          showInLegend: false,
          innerSize: "60%",
          dataLabels: {
            enabled: false,
            ///distance: -14,
            color: "white",
            style: {
              fontweight: "bold",
              fontsize: 50
            }
          }
        }
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data !== prevProps.data) {
      this.setState({
        chartData: {
          ...this.state.chartData,
          series: [
            {
              data: this.props.data
            }
          ]
        }
      });
    }
  }

  render() {
    return (
      <>
        <HighchartsReact
          highcharts={Highcharts}
          options={this.state.chartData}
        />
      </>
    );
  }
}

export default PieChart;