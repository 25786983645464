import React, {useEffect} from 'react'

import Theme  from 'components/v2/Theme'

import Config from 'config'

import {
    Switch,
    Route,
    Redirect
} from "react-router-dom"

import Countdown from 'pages/countdown';

import Dashboard from 'pages/dashboard';

import ModTheme from 'components/v2/Theme/mod'

import LocalDeVoto from 'pages/local-de-voto';

import PesquisaRecenseado from 'pages/pesquisa-recenseado';

import MapView from 'pages/map'

import NotFound from 'pages/404'

import { connect } from 'react-redux'

import { fetchTime, tick } from 'store/countdown/actions'

import { getRegions } from 'store/regions/actions'

import VotosEleitos from 'components/Charts/VotosEleitos'

import ElectedDeputiesStats from 'components/Charts/ElectedDeputiesStats'

import Manutencao from 'components/v2/Manutencao'

import Data from 'components/Theme/Data';

import RankingVertical from 'components/v2/Ranking/ranking.vertical';


const NOSIUUID = "-704dd6db-50a7-474a-8cd2-b95e708e7c03-p21";

const Routes = (props) => {
   
    const { isElectionDay } = props.countdown;

    const removeListener = () => {

        window.removeEventListener('focus', props.fetchTime);

    }

    useEffect( () => {

        props.getRegions(); 

       // props.getDeputados(); 

        return () => removeListener;
        
    }, [] );


    const valid = Config.dates.onRouteEnterValidation ? isElectionDay : true;

    //console.log( props.elections.update )

    return props.elections.update?.status_site === "2" ? (
        <Data>

            <Manutencao></Manutencao>

        </Data>
    ) : (
        <Switch>

            {<Route path="/pesquisa-recenseado" exact render={(props) => {
                    return <PesquisaRecenseado />
            }}/>}

            {
                /** 
                 * <Route path="/local-de-voto" exact render={(props) => {
                        return <LocalDeVoto />
                    }} />
                 * 
                */
            }
            
            
            <Route path={`/${Config.mapURL}`} exact render={ (props)=>{
                console.log(valid)
                //if(valid)
                    return (
                        <Theme showNavDetails={false} title={<div>Detalhes de mesas</div>}> 
                            <MapView></MapView>
                        </Theme>
                    )
                //else return <Redirect to="/"></Redirect>
            } }/>

            <Route path={`/${Config.dashURL}`} render={()=>{
          
                //if(valid)
                    return (
                        <Theme>
                            <Dashboard></Dashboard>
                        </Theme>
                    )
                //else return <Redirect to="/"></Redirect>
            }}/>
         

            {/**NOSI Views ONLY */}

            <Route path={`/${Config.dashURL+NOSIUUID}`} render={()=>{
                return (
                    <Theme>
                        <Dashboard></Dashboard>
                    </Theme>
                )
            }}/>
            <Route path={`/${Config.mapURL+NOSIUUID}`} exact render={ (props)=>{
                return (
                    <Theme showNavDetails={false} title={<div>Detalhes de mesas</div>}> 
                        <MapView></MapView>
                    </Theme>
                )
            } }/>

            
            


            {/***/}
            
            <Route path="/" exact render={()=>{
                if(!isElectionDay)
                    return <Countdown/>
                else
                    return <Redirect to={`/pesquisa-recenseado`}></Redirect>
            }}/>
            
            <Route path="/modulo/votos" exact render={()=>{
                
                if(!isElectionDay){
                    return <Countdown session="module"/>
                }
                else{
                    return (
                        <ModTheme>
                          <RankingVertical></RankingVertical>
                        </ModTheme>
                    )
                }
            }}/>

            

            {
                /** 
                 *  <Route path="/" exact render={()=>{
                return <Redirect to="/pesquisa-recenseado"></Redirect>
            }}/>
                */
            }
           

            <Route>
                <NotFound></NotFound>
            </Route>

        </Switch>
    )
}

const dispatchToProps = (dispatch)=>({
    fetchTime      : ()=>dispatch( fetchTime( new Date() ) ),
    tick           : (start_time)=>dispatch( tick(start_time) ),
    getRegions     : ()=>dispatch( getRegions() ),
    //getDeputados   : ()=>dispatch( getDeputados() )
})

export default connect(s=>({...s}), dispatchToProps) (Routes);

